/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Divider, Space, Tour } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { boardList, boardListColors, stages } from "utils/constants";
import { AiOutlinePlus, AiOutlineLock } from "react-icons/ai";
import Card from "components/Molecules/Card";
import useArticles from "hooks/useArticle";
import { stageChangeCondition } from "utils/helper";
import { fieldHelperTexts } from "utils/constants";
import Drawer from "components/Molecules/Drawer";
import BlogForm from "components/Molecules/Forms/Blog";
import { useAuth } from "context/auth";
import api from "utils/api";
import { useAlert } from "context/alert";
import BlogUpdate from "components/Molecules/Forms/BlogUpdate";
import isEqual from "react-fast-compare";
import usePrevious from "hooks/usePrevious";
import { Helmet } from 'react-helmet';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: grid,
  borderRadius: "2px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",

  // change background colour if dragging
  background: isDragging ? "grey" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#e5e5e5",
  padding: `0 0 ${grid}px`,
  width: 250,
});

const Board = () => {
  /* Product Tour Vars */
  const refwelcome = useRef(null);
  const refws = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const openFirstTime = () => {
    if (!localStorage.getItem("tour")) {
      setOpen(true);
      localStorage.setItem("tour", 1);
    }
  };
  const steps = [
    {
      title: "Welcome! Read all board column titles before we get started",
      description:
        "Green outlined headling column cards are ones you need to review or edit, orange outlined heading columns are where you submit to Kwegg algorithms to generate output. ",
      target: () => refwelcome.current,
    },
    {
      title: "Now lets ask Kwegg to generate outline sections of an article!",
      description:
        'Move this card to next column to ask Kwegg AI to generate article sections outline. Kwegg will automatically move it further to "AI Generated Outlines" stage.',
      target: () => ref3.current,
    },
    {
      title: "Throw your ideas to do the magic",
      description:
        'Provide your input thoughts for a new content as overview and click "Generate Title" text below Overview input. Now click + button to try.',
      target: () => ref2.current,
      mask: {
        style: {
          boxShadow: "inset 0 0 15px #fff",
        },
        color: "rgba(40, 0, 255, .4)",
      },
    },
    {
      title: "Thats all the learning you need",
      description:
        "Move articles from Generated Outlines to Generate Draft for Kwegg AI to start processing. Try editing or adding any number of workspaces from top left menu. Finally, reach out to us anytime over Chat on bottom right section.",
      target: () => refws.current,
      mask: {
        style: {
          boxShadow: "inset 0 0 15px #fff",
        },
        color: "rgba(40, 0, 255, .4)",
      },
    },
  ];
  // end product tour vars

  const { data: appData } = useAuth();
  const [list, setList] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDrawerCreateOpen, setCreateDrawer] = useState(false);
  const [isDrawerUpdateOpen, setUpdateDrawer] = useState(false);
  const [activeData, setActiveData] = useState(null);
  const { data = null, mutate: refetchArticles } = useArticles();
  const { error, success } = useAlert();
  const previousData = usePrevious(data);

  useEffect(() => {
    openFirstTime();
    if (data) {
      setList(data);
    }
  }, [isEqual(previousData, data)]);

  function onDragEnd(result) {
    const { source, destination, draggableId } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    if (sInd === dInd) {
      const items = reorder(list[sInd], source.index, destination.index);
      const newState = [...list];
      newState[sInd] = items;
      setList(newState);
    } else {
      if (stageChangeCondition(sInd, dInd)) {
        const result = move(list[sInd], list[dInd], source, destination);
        const newState = { ...list };
        const oldState = { ...list };
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];
        setList(newState);
        updateState(sInd, dInd, destination, source, draggableId, oldState);
      } else {
        error("you can only move forward and next stage");
      }
    }
  }

  const handleBlogUpdate = () => {
    setIsUpdated(true);
    setTimeout(() => {
      setIsUpdated(false);
    }, 5000);
  }

  const getPublishText = (data, appData) => {
    if (data?.stages == "CREATED_DRAFT") {
      return appData?.workspace?.canPublish ? "Publish" : "Move to Published";
    }
    return null;
  };

  const autoSave = (data, appData) => {
    if (data?.stages == "CREATED_DRAFT") {
      return appData?.workspace?.canPublish ? "Publish" : "Move to Published";
    }
    return null;
  };

  const updateState = async (
    sId,
    dId,
    destination,
    source,
    draggableId,
    oldState
  ) => {
    try {
      await api.put(`article/${draggableId}`, {
        stages: dId,
      });
      success("Stage is updated");
    } catch (err) {
      console.log(err);
      error(err?.message ? err.message : "Error while updating the stage..!");
      setList(oldState);
    }
  };

  const setDataForEditing = (data) => {
    setActiveData(data);
    setUpdateDrawer(true);
  };

  const deleteArticle = async (id) => {
    try {
      await api.put(`article/${id}`, { stages: stages.ARCHIVED });
      success("Article deleted successfully");
      refetchArticles();
    } catch (err) {
      error("Error while deleting article");
    }
  };

  const onPublish = async () => {
    try {
      await api.put(`article/${activeData.id}`, {
        stages: "PUBLISHED",
      });
      setUpdateDrawer(false);
      success("Published");
      refetchArticles();
    } catch (err) {
      console.log(err);
      error("Error! " + err);
      alert("Error! " + err);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{appData?.workspace?.name + ' | Board'}</title>
      </Helmet>
      <Tour
        open={open}
        onClose={() => setOpen(false)}
        steps={steps}
        mask={{
          style: {
            boxShadow: "inset 0 0 15px #333",
          },
          color: "rgba(80, 255, 255, .4)",
        }}
      />
      <Drawer
        isOpen={isDrawerCreateOpen}
        setDrawer={setCreateDrawer}
        title="Create article"
        subTitle="You can create your article here."
      >
        <BlogForm
          workspace={appData?.workspace?.id}
          setDrawer={setCreateDrawer}
          refetch={refetchArticles}
        />
      </Drawer>
      <Drawer
        isOpen={isDrawerUpdateOpen}
        setDrawer={setUpdateDrawer}
        title="Update article"
        subTitle={isUpdated ? 'Updated just now....' : 'Update before publishing'}
        buttonTitle={getPublishText(activeData, appData)}
        buttonTitleHandler={onPublish}
      >
        {isDrawerUpdateOpen && (
          <BlogUpdate
            setDrawer={setUpdateDrawer}
            refetch={refetchArticles}
            data={activeData}
            onBlogUpdate={handleBlogUpdate}
          />
        )}
      </Drawer>
      <div className="flex gap-6">
        <DragDropContext onDragEnd={onDragEnd}>
          {boardList.map((el, ind) => (
            <Droppable key={el} droppableId={el}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <div
                    className="bg-white w-full border-t-4 rounded-t-md mb-5"
                    style={{
                      borderColor: boardListColors[el],
                      boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <div className="p-3 flex justify-between">
                      <div>
                        {" "}
                        {fieldHelperTexts[el]}
                        <br />
                        <small>{fieldHelperTexts[el + "_INFO"]}</small>{" "}
                      </div>
                      <div className="flex items-center">
                        <div
                          className="p-0.5 leading-3 mr-1 text-xs rounded-full border-black-200"
                          style={{
                            color: boardListColors[el],
                            borderWidth: "0.5px",
                          }}
                        >
                          {list?.[el]?.length || 0}
                        </div>
                        {ind === 0 && (
                          <div
                            ref={ref2}
                            className="cursor-pointer"
                            onClick={() => setCreateDrawer(true)}
                          >
                            <AiOutlinePlus className="text-orange-700 text-lg" />
                          </div>
                        )}
                        {(ind === 1 || ind === 3) && (
                          <div>
                            <AiOutlineLock className="text-orange-700 text-lg" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {list?.[el]?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={ind === 1 || ind === 3}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            ref={ind === 0 && index === 0 ? ref3 : undefined}
                          >
                            <Card
                              data={item}
                              stage={el.name}
                              deleteArticle={deleteArticle}
                              openDrawer={setDataForEditing}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </div>

      <Button type="link" onClick={() => setOpen(true)}>
        Quick Tour
      </Button>
      <span className="text-sm">v1.1-bbae698</span>
    </div>
  );
};
export default Board;
