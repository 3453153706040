import React from "react";
import PropTypes from "prop-types";

const Avatar = ({ src, active }) => {
  return (
    <div>
      <div className="relative">
        <img className="w-10 h-10 rounded-full" src={src} alt="" />
        {active && (
          <span className="top-0 left-7 absolute w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full" />
        )}
      </div>
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

Avatar.defaultProps = {
  src: "",
  active: false,
};

export default Avatar;
