import { arrangeArticles } from "utils/arrangeArticels";
import { useQuery } from "./query";
import { useAuth } from "context/auth";

function useArticles() {
  const { data: appData } = useAuth();
  const { data, error, isLoading, mutate } = useQuery(
    `article/${appData?.workspace?.id || ""}`, 2000
  );

  return {
    data: appData?.workspace?.id ? arrangeArticles(data): [],
    isLoading,
    isError: error,
    mutate
  };
}

export default useArticles;
