import React, { forwardRef } from "react";
import { editorToolbar } from "utils/constants.js";

const renderOptions = (formatData) => {
  const { className, options } = formatData;
  return (
    <select className={className}>
      <option selected="selected"></option>
      {options.map((value) => {
        return <option value={value}></option>;
      })}
    </select>
  );
};
const renderSingle = (formatData) => {
  const { className, value } = formatData;
  return <button className={className} value={value}></button>;
};
const CustomToolbar = forwardRef((props, ref) => (
  <div id="toolbar" class="top-sticky" ref={ref} >
    {editorToolbar.map((classes,i) => {
      return (
        <span key={i} className="ql-formats">
          {classes.map((formatData) => {
            return formatData.options
              ? renderOptions(formatData)
              : renderSingle(formatData);
          })}
        </span>
      );
    })}
  </div>
));
export default CustomToolbar;
