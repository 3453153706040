/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import rightCheveron from "images/rightCheveron.png";
import api from "utils/api";
import { AlertContext } from "context/alert";
const MagicLink = ({ setShow, email }) => {

  const alert = useContext(AlertContext);

  useEffect(() => {
    if (email) {
      try {
        api.post('auth/magiclink',{email});
        alert.success('Magic link sent to your mail')
      } catch (error) {
        alert.error('Error while sending Magic link..!')
      }
    }
  }, []);

  return (
    <div className="pl-6 pr-6">
      <div
        className="absolute top-3 left-3 cursor-pointer"
        onClick={() => setShow(false)}
      >
        <img
          src={rightCheveron}
          alt="right cheveron for back button"
          className="h-5"
        />
      </div>
      <div className="text-4xl font-bold text-center">Check Your Email</div>
      <div className="font-normal text-md text-center mt-16">
        <div>We have mailed you a magic link on your email address</div>
        <div className="text-blue-light" > {email} </div>
        <div>Click on the magic link to sign in to your account</div>
      </div>
    </div>
  );
};

MagicLink.propTypes = {
  setShow: PropTypes.func,
};

export default MagicLink;
