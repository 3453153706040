import React from "react";
import Tabs from "antd/lib/tabs";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { AiOutlineCalendar } from "react-icons/ai";
import Board from "containers/Board";
import PublishList from "containers/Published";
const Blogs = () => {
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        size="large"
        items={[
          {
            label: (
              <div className="flex items-center">
                <div className="mr-2">
                  <HiOutlineViewGridAdd className="text-lg text-black-200" />
                </div>
                <div className="font-poppin text-black-200">Board inline</div>
              </div>
            ),
            key: "1",
            children: <Board />
          },
          {
            label: (
              <div className="flex items-center">
                <div className="mr-2">
                  <div className="text-lg text-black-200">
                    <AiOutlineCalendar />
                  </div>
                </div>
                <div className="font-poppin text-black-200"> Published </div>
              </div>
            ),
            key: "2",
            children: <PublishList />,
          },
        ]}
      />
    </div>
  );
};

export default Blogs;
