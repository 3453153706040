/* eslint-disable eqeqeq */
import { AiFillHome, AiFillEdit, AiFillSetting } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";

export const baseUrl = "https://api.kwegg.com/";
//export const baseUrl = "http://localhost:3000/";
//VENDORS
export const paddleConfig = {
  'STARTER_PRODUCTID': '813208',
  'STARTER_PRICE': 19.95,
  'BLOGPACK_PRODUCTID': '813794',
  'BLOGPACK_PRICE': 25
};
//SANDBOX
/*export const paddleConfig = {
  'STARTER_PRODUCTID': '45001',
  'STARTER_PRICE': 19.95,
  'BLOGPACK_PRODUCTID': '45069',
  'BLOGPACK_PRICE': 25
};*/

export const demoWorkspace = {
  name: "Genius7",
  overview: "Genius7 is an AI company that specializes in generating short-form videos from provided prompts. Geinus7 AI analyses prompt or idea and quickly generate a script, storyboard, and suggested shots for a short-form video. Users can edit or review the script, and provide existing image or footages needed before AI generates video. ",
  features: "Quick script generation from written prompts|AI-generated storyboard and suggested shots|Editing and customization of generated content|Stock footage and music library integration|High-quality, professional-looking short-form videos",
  blogCategories: "AI Video Production|Short-form video creation|Video content generation|prompt-based video production|automated video editing|script to video technology|customizable video creation"
};

const commonStyle = (index, key) => ({
  fontSize: "30px",
  color: ` ${key == index ? "#0C7FDA" : "#5D7285 "}`,
  margin: "auto",
});

export const menuItems = (key) => {
  return [
    // {
    //   key: 2,
    //   icon: <AiFillHome style={commonStyle(2, key)} id="sidebar-icon-aichat" />,
    //   label: (
    //     <div
    //       className={`font-poppin text-base ${
    //         key == 2 ? "font-semibold" : "font-regular"
    //       }`}
    //     >
    //       Dashboard
    //     </div>
    //   ),
    // },
    {
      key: 3,
      icon: <AiFillEdit style={commonStyle(3, key)} id="sidebar-icon-aichat" />,
      label: <div className="font-poppin text-base">Articles</div>,
    },
    // {
    //   key: 4,
    //   icon: (
    //     <HiUserGroup style={commonStyle(4, key)} id="sidebar-icon-aichat" />
    //   ),
    //   label: <div className="font-poppin text-base">Members</div>,
    // },
    {
      key: 5,
      icon: (
        <AiFillSetting style={commonStyle(5, key)} id="sidebar-icon-aichat" />
      ),
      label: <div className="font-poppin text-base">Settings</div>,
    },
  ];
};


export const stages = {
  "ARCHIVED": "ARCHIVED"
};

export const boardList = [
  "TITLE_CREATED",
  "CREATE_OUTLINE",
  "CREATED_OUTLINE",
  "CREATE_DRAFT",
  "CREATED_DRAFT",
];

export const boardListColors = {
  "TITLE_CREATED" : "#04C818",
  "CREATE_OUTLINE": "#FF9900",
  "CREATED_OUTLINE": "#04C818",
  "CREATE_DRAFT": "#FF9900",
  "CREATED_DRAFT": "#04C818",
};

export const sidebarLinks = {
  2: "/dashboard/",
  3: "/dashboard/blogs",
  4: "/dashboard/members",
  5: "/dashboard/settings",
};

export const fieldLabels = {
  "CONTEXTUALLY_ONCE": "Medium",
  "SEO_BOOST_CONTEXTUALLY_ONCE": "SEO Boost",
  "NEWS": "News",
  "EACH_SEPERATELY": "Long",
  "GPT4_MEDIUM": "GPT-4",
  "NEWSLETTER": "Newsletter",
  "PRESS_RELEASE": "Press Release" 
};

export const fieldHelperTexts = {
  "CONTEXTUALLY_ONCE": "Costs 1 unit. GPT-3.5 'Medium' produces content expanding all outlines in one context generating medium length articles having minimum repetitions. ",
  "SEO_BOOST_CONTEXTUALLY_ONCE": "Costs 1 additional unit. SEO Boost researches best performing articles currently on topic to generate winning content and outlines. We recommend reviewing the content critically as your best competitors brands might show up and rewrite accordingly.",
  "EACH_SEPERATELY": "Costs 2 units. 'Long' produces longform content having slight repetitions expanding each outline seperately. ",
  "NEWS": "Costs 1 unit. Generate news providing original overview and URLs to to do research.",
  "GPT4_MEDIUM": "Costs 5 units each. GPT-4 powered medium blogs. Higher accuracy and quality.",
  "NEWSLETTER": "Costs 1 unit. Generate newsetters with overview as context of the newsletter.",
  "PRESS_RELEASE": "Costs 1 unit. Generate press release with overview as context of the press release.",
  "OVERVIEW_HELPER": "Crisp and short overview here. e.g.,Kwegg launching new feature of auto generating article title from provided overview. Click Create new article button. Enter Overview providing enough description. Click 'Generate Title from overview'. Title is automatically generated. Rest of the blog talk about other features of Kwegg in how this overview can further generate outlines and final draft. ",
  "TITLE_HELPER": "Blog title here. e.g., Kwegg Launches Auto Generated Titles from Article Overview Provided",
  "TONE_HELPER": "Select Tone ( Recommended 1 or 2 )",
  "STRATEGY_HELPER": "Select Strategy ( learn more by selecting )",
  "WORKSPACE_NAME": "e.g., Kwegg",
  "WORKSPACE_FEATURES": "e.g., Create AI Generated Blogs trained from workspace information.",
  "WORKSPACE_OVERVIEW": "e.g., Kwegg is the ultimate AI-powered blog writing assistant that creates quality content tailored to your company, learning more about you with each post. Kwegg helps your business create engaging, publish-ready content faster and easier than ever.",
  "WORKSPACE_BLOG_CATEGORIES": "AI Writer, SaaS SEO, Creative Content Writing, etc.",
  "SPACE_OVERVIEW_CHARLIMIT": 600,
  "TITLE_OVERVIEW_CHARLIMIT": 600,
  "TITLE_CREATED": "Create Titles",
  "CREATE_OUTLINE": "Generate Outlines",
  "CREATED_OUTLINE": "AI Generated Outlines",
  "CREATE_DRAFT": "Generate Draft",
  "CREATED_DRAFT": "AI Generated Draft",
  "TITLE_CREATED_INFO": "AI generate titles below or you can add one",
  "CREATE_OUTLINE_INFO": "move cards below to generate outlines from title",
  "CREATED_OUTLINE_INFO": "AI will move generated outlines from the previous step here",
  "CREATE_DRAFT_INFO": "move cards here to generate AI Draft",
  "CREATED_DRAFT_INFO": "AI generated drafts for your review will appear here",
  "BLOG_DEFAULT_CATEGORIES": [{value: "Company News", label: "Company News"}, {value: "Technology", label: "Technology"},{value: "How To", label: "How To"},
  {value: "Industry Trends", label: "Industry Trends"}, {value: "Product Updates", label: "Product Updates"}, {value: "Business Insights", label: "Business Insights"},
  {value: "Leadership Advice", label: "Leadership Advice"}, {value: "Corporate Responsibility", label: "Corporate Responsibility"}, {value: "Employee Spotlights", label: "Employee Spotlights"},
  {value: "Professional Development", label: "Professional Development"}, {value: "Recruiting Tips", label: "Recruiting Tips"}, {value: "Company Culture", label: "Company Culture"},
  {value: "Sustainability", label: "Sustainability"}, {value: "Technology Solutions", label: "Technology Solutions"}, {value: "Client Success Stories", label: "Client Success Stories"},
                            ]
};

const colors = ["red", "green", "blue", "orange", "violet", "white"];
export const editorToolbar = [
  [
    {
      className: "ql-font",
      options: ["serif", "monospace"],
    },
    {
      className: "ql-size",
      options: ["small", "large", "huge"],
    },
  ],
  [
    { className: "ql-bold" },
    { className: "ql-italic" },
    { className: "ql-underline" },
    { className: "ql-strike" },
  ],
  [
    {
      className: "ql-color",
      options: colors,
    },
    {
      className: "ql-background",
      options: colors,
    },
  ],
  [
    {
      className: "ql-script",
      value: "sub",
    },
    {
      className: "ql-script",
      value: "super",
    },
  ],
  [
    {
      className: "ql-header",
      value: "1",
    },
    {
      className: "ql-header",
      value: "2",
    },
    {
      className: "ql-blockquote",
    },
    {
      className: "ql-code-block",
    },
  ],
  [
    {
      className: "ql-list",
      value: "ordered",
    },
    {
      className: "ql-list",
      value: "bullet",
    },
    {
      className: "ql-indent",
      value: "-1",
    },
    {
      className: "ql-indent",
      value: "+1",
    },
  ],
  [
    {
      className: "ql-direction",
      value: "rtl",
    },
    {
      className: "ql-align",
      options: ["right", "center", "justify"],
    },
  ],
  [
    { className: "ql-link" },
    { className: "ql-image" },
    { className: "ql-video" },
    { className: "ql-formula" },
  ],
];
