import React from "react";
import PropTypes from "prop-types";
import AntdDrawer from "antd/lib/drawer";
import CustomButton from "components/Atoms/CustomButton";
// import Avatar from "antd/lib/avatar";

const Drawer = ({ isOpen, setDrawer, children, title, subTitle, buttonTitle, buttonTitleHandler}) => {
  return (
    <>
      <AntdDrawer
        title={
          <div>
            <div className="float-left">
              <div> {title} </div>
              <div className="text-sm font-normal"> {subTitle} </div>
            </div>
            {( buttonTitle!=null ) && (
              <div className="float-right">
                <CustomButton title={buttonTitle} onClick={buttonTitleHandler}>Button</CustomButton>
              </div>
            )}
            
          </div>
        }
        width={'60%'}
        onClose={() => setDrawer(false)}
        open={isOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={<></>}
      >
        {children}
      </AntdDrawer>
    </>
  );
};

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setDrawer: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

export default Drawer;
