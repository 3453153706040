import { useAuth } from "context/auth";
import { useQuery } from "./query";
import { fieldLabels } from "utils/constants";

function useStrategy() {
  const { data: appData } = useAuth();
  const { data, error, isLoading } = useQuery(
    `article/${appData?.workspace?.id || ""}/strategies`
  );

  return {
    data: data?.map((item) => ({ value: item, label: fieldLabels[item] })),
    isLoading,
    isError: error,
  };
}

export default useStrategy;
