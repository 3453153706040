import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const IndexRoute = () => {
const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
      if (token) {
        navigate('/dashboard/blogs');
      } else {
        navigate('/login')
      }
    }, [])

  return (
    <div></div>
  )
}

export default IndexRoute