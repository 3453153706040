import { useAuth } from "context/auth";
import { useQuery } from "./query";
import { remove_ } from "utils/helper";

function useTone() {
  const { data: appData } = useAuth();
  const { data, error, isLoading } = useQuery(
    `article/${appData?.workspace?.id || ""}/tones`
  );

  return {
    data: data?.map((item) => ({ value: item, label: remove_(item) })),
    isLoading,
    isError: error,
  };
}

export default useTone;
