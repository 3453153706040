import React, { useState } from "react";
import { Button,Modal, Space, Card, Result, Typography, Popconfirm } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import CustomButton from "components/Atoms/CustomButton";
import InputNumber from "antd/lib/input-number";
import { useAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useEffect } from "react";
import api from "utils/api";
import { AiFillCheckCircle } from "react-icons/ai";
import { paddleConfig } from "utils/constants";
import { Helmet } from 'react-helmet';
const subPrice = paddleConfig['STARTER_PRICE'];
const per200Price = paddleConfig['BLOGPACK_PRICE'];

const Billings = () => {
  const [qty, setQty] = useState(1);
  const { error, success } = useAlert();
  const { data, setData } = useAuth();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailedModalOpen, setIsFailedModalOpen] = useState(false);
  const { Text, Link } = Typography;
  const showSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessOk = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSuccessCancel = () => {
    setIsSuccessModalOpen(false);
  };

  const showFailedModal = () => {
    setIsFailedModalOpen(true);
  };

  const handleFailedOk = () => {
    setIsFailedModalOpen(false);
  };

  const handleFailedCancel = () => {
    setIsFailedModalOpen(false);
  };

  const Paddle = window.Paddle;

  let interval;

  const getSubscription = async () => {
    try {
      const res = await api.get("profile/subscription/billing-section");
      setData({ subscription: res });
      if (interval) {
        clearInterval(interval);
      }
    } catch (err) {
      console.error(err)
      error("Error while getting the subscription");
    }
  };

  const confirmAddOn = () => {
    const promise = api.post("profile/oneoff-paddle",{amount: qty * per200Price});
    promise.then((res) => {
      setData({ subscription: res });
      showSuccessModal();
      success("Units added to your account");
    }).catch((err) => {
      console.error(err);
      checkoutAddons(qty);
      err?.message ? error(err.message) : error(err);
    });
    return promise;
  }

  useEffect(() => {
    getSubscription();
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const checkoutClosed = (data) => {
    showFailedModal();
  }
  
  const checkoutComplete = (data) => {
    showSuccessModal();
  }

  const checkout = () => {
    if (!data?.user?.email && !data?.user?.id) {
      error("Please login to continue");
      return;
    }
    interval = setInterval(() => {
      getSubscription();
    }, 2000);
    Paddle.Checkout.open({
      product: paddleConfig['STARTER_PRODUCTID'],
      email: data.user.email,
      successCallback: checkoutComplete,
      closeCallback: checkoutClosed,
      customData: {
        internal_user_id: data.user.id,
      },
    });
  };

  const checkoutAddons = (quantity) => {
    if (!data?.user?.email && !data?.user?.id) {
      error("Please login to continue");
      return;
    }
    interval = setInterval(() => {
      getSubscription();
    }, 2000);
    Paddle.Checkout.open({
      product: paddleConfig['BLOGPACK_PRODUCTID'],
      quantity: quantity,
      email: data.user.email,
      successCallback: checkoutComplete,
      closeCallback: checkoutClosed,
      customData: {
        internal_user_id: data.user.id,
      },
    });
  };

  const isSubscription = data?.subscription?.active || false;
  const subscription = data?.subscription;

  return (
    <div>
      <Helmet>
        <title>{data?.workspace?.name + ' | Billing'}</title>
      </Helmet>
      <Modal title="" open={isSuccessModalOpen} onOk={handleSuccessOk} onCancel={handleSuccessCancel}>
        <Result
          status="success"
          title="Successfully Purchased"
          subTitle="Cloud server configuration takes 1-5 minutes, please wait."
        />
      </Modal>
      <Modal title="" open={isFailedModalOpen} onOk={handleFailedOk} onCancel={handleFailedCancel}>
        <Result
          status="error"
          title="Subscription Failed"
          subTitle="Please try again or contact us"
        />
      </Modal>
      <Space direction="vertical" size={16}>
        <div>
          <div className="flex justify-between">
            <div>
              <div className="text-lg"> Plans </div>
            </div>
          </div>
          <div className="mt-6">
            <Card
              title="Monthly Subscription"
              extra={
                isSubscription ? (
                  <div className="flex items-center gap-1">
                    {" "}
                    <div>Active</div>{" "}
                    <div>
                      {" "}
                      <AiFillCheckCircle />{" "}
                    </div>{" "}
                  </div>
                ) : (
                  <CustomButton title="Subscribe" onClick={checkout} />
                )
              }
              style={{ width: 600 }}
            >
              <div className="flex justify-between">
                <div className="flex">
                  <div className="text-2xl"><b>${subPrice}</b></div>
                  <div className="self-end ml-1">per month</div>
                </div>
                { isSubscription && <div className="text-[#12DB00]" >
                    <Text strong type="success">Renews on {new Date(data?.subscription?.subrenew).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                    </Text>
                </div>}
              </div>
              <div>Get Started with Kwegg AI Content Productivity System. </div>
              <ul className="list-disc mt-2 ml-3">
                <li>100 Units/m Limit ( 1 Medium Article = 1 Unit, 1 AI Research = 1 Unit, 1 Long Article = 1 Unit )</li>
                <li>
                  Publish Directly to Wordpress ( more integrations coming soon)
                </li>
                <li>Add on with as many units as you want that never expire</li>
              </ul>
              <div>
                {subscription?.cancelUrl && (
                  <Space>
                    <Button type="link" href={subscription?.cancelUrl} target="_blank" danger>Cancel</Button>
                    <Button type="link" href={subscription?.updateUrl} target="_blank">Update Payment</Button>
                  </Space>
                )}
              </div>
            </Card>
          </div>
        </div>
        <div>
          <div className="mt-6">
            <Card
              title="Add On Units Pack"
              style={{ width: 600 }}
            >
              <div className="flex">
                <div className="text-2xl">${per200Price}</div>
                <div className="self-end ml-1">per 100 units pack</div>
              </div>

              <div className="flex">
                <div className="text-sm"><Text keyboard>{subscription?.addonBlogs - subscription?.usedBlogs} Units Remaining </Text></div>
              </div>
              <div className="flex justify-between mt-3">
                <div>
                  {" "}
                  <InputNumber
                    min={1}
                    max={50}
                    onChange={setQty}
                    value={qty}
                  />{" "}
                  pack
                </div>
                <div>
                  {" "}
                  <div className="flex">
                    <div className="text-2xl"><b>${per200Price * qty}</b></div>
                    <div className="self-end ml-1">total</div>
                  </div>{" "}
                </div>
              </div>

              <div className="w-[150px] mt-3" >
                <Popconfirm
                  title="Confirm Purchase"
                  description="Kwegg will charge credit card on file."
                  onConfirm={confirmAddOn}
                  icon={<CreditCardOutlined style={{ color: 'green' }} />}
                >
                  {isSubscription ? <Button type="primary">Buy Pack</Button>: "Addons are available to subscribed users"}
                </Popconfirm>
                {(!isSubscription && (subscription?.addonBlogs - subscription?.usedBlogs) > 0) && " and can be used only during subscription."}
              </div>
            </Card>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default Billings;
