import React from "react";
import desktopImg from "images/desktop.png";
import WorkspaceForm from "components/Molecules/Forms/Workspace";
import { useParams } from "react-router-dom";
import WorkspaceFormEdit from "components/Molecules/Forms/EditWorkspace";
import { useAuth } from "context/auth";
import { Helmet } from 'react-helmet';

const WorkspaceCreate = (props) => {
  const { workspaceId } = useParams();
  const { data: appData } = useAuth();

  return (
    <div className="login-background w-full h-screen font-poppin">
      <Helmet>
        <title>{workspaceId? appData?.workspace?.name+' | Edit Workspace' : 'New Workspace'}</title>
      </Helmet>
      <div className="max-w-5xl mx-auto px-8">
        <div className="flex items-center justify-center h-screen">
          <div className="bg-white w-full md:w-[850px] py-16 relative bg-gray-100 rounded">
            <div className="px-16 text-center">
              <div className="text-3xl mt-2"> {workspaceId ? "Edit Your Workspace" : "Create Workspace" }</div>
              <div className="text-sm mt-2">
              Your input fuels Kwegg AI's content generation. Keep it concise, informative and impactful.
              </div>
            </div>
            {workspaceId ? (
              <WorkspaceFormEdit id={workspaceId} />
            ) : (
              <WorkspaceForm />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

WorkspaceCreate.propTypes = {};

export default WorkspaceCreate;
