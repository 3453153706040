import routes from "router";
import { RouterProvider } from "react-router-dom";
import AlertProvider from "context/alert";
import AuthProvider from "context/auth";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <AlertProvider>
          <RouterProvider router={routes} />
        </AlertProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
