import CustomButton from "components/Atoms/CustomButton";
import Input from "components/Atoms/Input";
import { useAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import api from "utils/api";
import { Spin } from "antd";
import { useState } from "react";

const WordpressForm = ({ onClose, data, refetch }) => {
  const { success, error } = useAlert();
  const { data: appData } = useAuth();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (data?.id) {
      formik.setValues(JSON.parse(data.creds))
    }
  }, [data])


  const formik = useFormik({
    initialValues: {
      url: "",
      password: "",
      username: "",
    },
    onSubmit: async (values) => {
      setProcessing(true);
      const reqObj = {
        type: "WORDPRESS",
        creds: JSON.stringify(values),
        isEnabled: true,
      };
      if (data?.id) {
        reqObj.isEnabled = data.isEnabled;
        reqObj.id = data.id
      }
      try {
        await api.post(`integration/${appData?.workspace?.id}`, reqObj);
        success("Workpress Creds are updated..!");
        onClose();
        refetch();
      } catch (err) {
        console.log(err);
        error("Error while updating the wordpress creds. " + err.message);
      }
      setProcessing(false);
    },
  });
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Input
              name="Url"
              id="url"
              onChange={formik.handleChange}
              value={formik.values.url}
              error={formik.errors.url}
              touched={formik.touched.url}
            />
          </div>
          <div className="flex justify-between gap-4">
            <div className="flex-1">
              <Input
                name="Username"
                id="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                error={formik.errors.username}
                touched={formik.touched.username}
              />
            </div>{" "}
            <div className="flex-1">
              <Input
                name="Password"
                type="password"
                id="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.errors.password}
                touched={formik.touched.password}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          {processing && (<Spin/>)}
          <div className="">
            <CustomButton
              title="Cancel"
              type="button"
              varient="error"
              onClick={onClose}
            />
          </div>
          <div>
            <CustomButton varient={processing ? "disabled":"success"} title={ data?.id ? "Update" :"Activate"} type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WordpressForm;
