import { useQuery } from "./query";

function useSubscription() {
  const { data, error, isLoading } = useQuery(
    `profile/subscription/sidebar`, 2000
  );

  return {
    data,
    isLoading,
    isError: error
  };
}

export default useSubscription;
