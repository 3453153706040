import React, { useState } from "react";
import { useFormik } from "formik";
import Input, { TextArea } from "components/Atoms/Input";
import CustomButton from "components/Atoms/CustomButton";
import Space from "antd/lib/space";
import { fieldHelperTexts } from "utils/constants";
import {Col, Row, Spin, Divider} from 'antd';

import api from "utils/api";
import Select from "components/Atoms/Select";
import useStrategy from "hooks/useStrategy";
import useTone from "hooks/useTone";
import { useAlert } from "context/alert";
import { dangerousHtmlMarkup, copyTextToClipboard } from "utils/helper";

const BlogForm = ({ workspace, setDrawer, refetch }) => {
  const { data: strategies } = useStrategy();
  const { data: tones } = useTone();
  const { success, error } = useAlert();
  const [research, setResearch] = useState({});
  const [researching, setResearching] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      overview: "",
      research: {},
      tone: [],
      strategy: [],
      titleStrategy: 'informative'
    },

    onSubmit: async (values, actions) => {
      try {
        await api.post(`article/${workspace}`, {
          ...values,
          tone: values.tone.join("|"),
          strategy: values.strategy,
          metas: JSON.stringify({research: research, source_urls: values.source_urls?.join(",")})
        });
        refetch();
        success("Article created!");
        actions.resetForm();
        setResearch({});
        setDrawer(false);
      } catch (err) {
        console.log(err);
        error((err.message) ? err.message[0] : "Error while creating article");
      }
    },
  });

  const clear = () => {
    formik.resetForm();
    setResearch({});
    setDrawer(false);
  }

  const generateTitle = async (e) => {
    e.preventDefault();
    formik.setFieldValue('title', 'Generating..');
    try {
      var resp = await api.post(`article/${workspace}/generate-title`, {
        idea: formik.values.overview,
        titleStrategy: formik.values.titleStrategy
      });
      formik.setFieldValue('title', resp);
      success("Title generated");
    } catch (err) {
      formik.setFieldValue('title', formik.values.title);
      error("Error while generating title");
    }
  };

  const generateResearch = async (e) => {
    e.preventDefault();
    setResearch({});
    setResearching(true);

    /*setResearch({
      "summary": "- Allu Arjun, a Telugu superstar, has a net worth of Rs 350 crores ($47 million) as of June 2022.\n- A significant portion of his income comes from high-value film deals estimated at around Rs 24 crores annually.\n- Allu Arjun owns a luxurious 8,000-square-foot house in Jubilee Hills, Hyderabad, which cost Rs 100 crores and includes a swimming pool, gym, home theatre, and play area.\n- He earns Rs 4 crores from brand endorsements and has invested in start-ups like CallHealth Services.\n- Allu Arjun owns a fleet of vehicles including a Range Rover Vogue, Mercedes GLE 350d, Jaguar XJ L, Volvo XC90 T8 Excellence, BMW X6 M Sport, and a Hummer H2.\n- Despite his wealth, Allu Arjun remains humble and often gives back to his community through charitable donations.",
      "webPages": [{
        "id": "https://api.bing.microsoft.com/api/v7/#WebPages.1",
        "name": "From Rs 100 crore house to Rs 75 Lakh Hummer: Pushpa actor Allu Arjun’s ...",
        "url": "https://www.financialexpress.com/lifestyle/from-rs-100-crore-house-to-a-rs-75-lakh-hummer-pushpa-actor-allu-arjuns-5-most-expensive-possessions/2963498/",
        "isFamilyFriendly": true,
        "displayUrl": "https://www.financialexpress.com/lifestyle/from-rs-100-crore-house-to-a-rs-75-lakh...",
        "snippet": "Allu Arjun bought a customised vanity van in 2019. Painted in black with his initials AA, the Falcon has beautiful interiors – A huge television set, a comfortable recliner, a fridge, and more.",
        "dateLastCrawled": "2023-03-22T16:32:00.0000000Z",
        "language": "en",
        "isNavigational": false,
        "summary": "Telugu superstar Allu Arjun has a net worth of Rs 350 crores ($47 million) as of June 2022, with a large part of his income coming from high-value film deals estimated at around Rs 24 crores annually. He has invested in luxurious items such as an 8,000-square-foot house in Jubilee Hills, Hyderabad, which cost Rs 100 crores and includes a swimming pool, gym, home theatre and play area. Allu Arjun also earns Rs 4 crores from brand endorsements and has invested in start-ups like CallHealth Services. He owns a fleet of vehicles including a Range Rover Vogue, Mercedes GLE 350d, Jaguar XJ L, Volvo XC90 T8 Excellence, BMW X6 M Sport and a Hummer H2. He also purchased a customised vanity van for over Rs 7 crores in 2019, and has a private jet whose cost is unknown.",
        "image": "https://www.financialexpress.com/wp-content/uploads/2023/01/Allu-Arjun.jpeg"
      }, {
        "id": "https://api.bing.microsoft.com/api/v7/#WebPages.2",
        "name": "Inside Pushpa star Allu Arjun’s crazy rich life – from Rolex watches ...",
        "url": "https://www.scmp.com/magazines/style/celebrity/article/3163432/inside-pushpa-star-allu-arjuns-crazy-rich-life-rolex",
        "isFamilyFriendly": true,
        "displayUrl": "https://www.scmp.com/.../3163432/inside-pushpa-star-allu-arjuns-crazy-rich-life-rolex",
        "snippet": "According to the GQ India, the wealthy actor has a Hummer H2, Range Rover Vogue, Jaguar XJL and a Volvo XC90 T8 Excellence. Arjun is often spotted out in his cars, regularly taking them for spins ...",
        "dateLastCrawled": "2022-06-30T03:57:00.0000000Z",
        "language": "en",
        "isNavigational": false,
        "summary": "Allu Arjun, the star of Indian film Pushpa: The Rise, leads a lavish lifestyle with a net worth of US$47 million. The Telugu actor has spent his fortune on luxury cars, including a Hummer H2, Range Rover, Jaguar and Volvo, as well as an extravagant watch collection that includes a Cartier Santos 100 XL and a Hublot Bang Bang Steel Carbon. Arjun has a multimillion-dollar mansion in Hyderabad and has flown to events in a private jet. He also owns a \"vanity van\" for on-set comfort during filming. Despite his wealth, Arjun has remained humble, often giving back to his community through charitable donations.",
        "image": "https://cdn.i-scmp.com/sites/default/files/styles/og_image_scmp_generic/public/d8/images/canvas/2022/01/14/692de538-9e76-46a9-af4a-f7a959003ca6_6e00b7c4.jpg?itok=CZlnEGfT&v=1642153449"
      }]
    });

    return;*/
    try {
      var resp = await api.post(`article/${workspace}/generate-research`, {
        idea: formik.values.overview,
      });
      setResearch(resp);
      success("Research Processed");
    } catch (err) {
      formik.setFieldValue('title', formik.values.title);
      error("Error while generating title");
    }
    setResearching(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <TextArea
            name="Idea Overview ( just add short, crisp original idea points here )"
            type="overview"
            id="overview"
            rows={3}
            onChange={formik.handleChange}
            value={formik.values.overview}
            error={formik.errors.overview}
            touched={formik.touched.overview}
            placeholder={fieldHelperTexts["OVERVIEW_HELPER"]}
          />
          <p align="right"><small>Kwegg AI gives a lot of emphasis on information you provide here. Provide crisp points, dont worry much about sentence structuring or grammar.</small></p>
          <p align="right"><small>{formik.values.overview.length} characters</small></p>
          
        </div>
        {formik.values.overview && (
          <div>
            {!researching&&(<p align="right" style={{color: "green"}}><a href="#" onClick={generateResearch}><b>Generate Internet Research Summary</b></a></p>)}
            <p align="right">{researching&&(<Spin/>)}{researching?("Researching. Takes time as we scrape pages."):("Costs 1 unit. You can improve your idea overview further using internet search summary.")}</p>
          </div>
        )}
        {research.summary && (<label>Generated Research (pro tip - scan points carefully and copy paste in idea overview to enrich with relevant information)</label>)}
        <div style={{background: "#f59e0b1a"}}>
        {research.summary && (
          <div style={{color: "#78350f", fontWeight: "bold", padding: "10px"}}>
            <div style={{fontSize: "16px"}}>Summary</div>
            <div dangerouslySetInnerHTML={dangerousHtmlMarkup(research.summary.split('\n').join('<br/>'))} ></div>
            <p align="right">
              <a href='#' onClick={()=>copyTextToClipboard(research.summary)} className="text-sm">
                <span className="text-blue">Copy</span>
              </a>
            </p>
          </div>
        )}
        {research.outlines && (
          <div style={{color: "#78350f", fontWeight: "bold", padding: "10px"}}>
            <div style={{fontSize: "16px"}}>SERP Outlines that will cover top results content</div>
            <div style={{fontSize: "8px"}}>Note that Kwegg will not use these outlines for outlines generation in next step as outlines will be generated from overview provided by you. Use these outlines which you can see in next step as well to enrich generated outlines. </div>
            <div dangerouslySetInnerHTML={dangerousHtmlMarkup(research.outlines.split('\n').join('<br/>'))} ></div>
            
          </div>
        )}
        <Space/>
        {research.summary && (<div style={{color: "#333333", marginTop: "20px"}}>Sources</div>)}
        {research.webPages && research.webPages.map((item, index) => (
            <div style={{background: "#222222", padding: "10px", marginBottom: "15px", borderRadius: "5px", color:"#ffffff" }}>
              <div>
                <Row>
                  <Col span={6}><img src={item.image} style={{padding: 5, maxHeight: "150px"}} /></Col>
                  <Col span={15}>
                    <a href={item.url} target="_blank">
                      <Row>
                        <span style={{fontSize: 14}}>{item.name}</span>
                      </Row>
                      <Row>
                      <span style={{fontSize: 10, color: "#f59e0b"}}>{item.url}</span>
                      </Row>
                      <Row>
                      <span style={{fontSize: 12}}>{item.snippet}</span>
                      </Row>
                    </a>
                  </Col>
                </Row>
                <Row>
                <span style={{fontSize: 11}}><b>Page Summary: </b>{item.summary}</span>
                </Row>
              </div>
            </div>
          ))}
        </div>
        <div>
          <Select
              name="Title Style"
              defaultValue="informative"
              id="titleStrategy"
              options={[
                { value: 'curiosity building', label: 'Curiosity' },
                { value: 'informative', label: 'Informative' },
                { value: 'catchy', label: 'Catchy' },
                { value: 'surprise creation', label: 'Surprise Users' },
                { value: 'asking a question style', label: 'Ask Question' },
                { value: 'negative style', label: 'Negative' },
                { value: 'email newsletter', label: 'Newsletter' },
                { value: 'press release', label: 'Press Release' },
                { value: 'explanatory how to style', label: 'How To' },
                { value: 'concrete facts style', label: 'Specificity' },
              ]}
              onChange={formik.setFieldValue}
              value={formik.values.titleStrategy}
              error={formik.errors.titleStrategy}
              touched={formik.touched.titleStrategy}
            />
            <p align="right" style={{color: "green"}}><a href="#" onClick={generateTitle}><b>Generate Title from Idea Overview</b></a></p>
        </div>
        <div>
          <Input
            name="Title"
            id="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.errors.title}
            touched={formik.touched.title}
            placeholder={fieldHelperTexts["TITLE_HELPER"]}
          />
        </div>
        <div>
          <Select
            name="Tone"
            mode="tags"
            id="tone"
            options={tones}
            onChange={formik.setFieldValue}
            value={formik.values.tone}
            error={formik.errors.tone}
            touched={formik.touched.tone}
            placeholder={fieldHelperTexts["TONE_HELPER"]}
          />
        </div>
        <div>
          <Select
            name="Article Strategy"
            id="strategy"
            options={strategies}
            onChange={formik.setFieldValue}
            value={formik.values.strategy}
            error={formik.errors.strategy}
            touched={formik.touched.strategy}
            placeholder={fieldHelperTexts["STRATEGY_HELPER"]}
          />
          <small>{(formik.values.strategy=='') ? "Select a strategy to learn more.":fieldHelperTexts[formik.values.strategy]}</small>
        </div>
        {formik.values.strategy=='NEWS' && (
          <div>
            <Select
              name="Enter news URL to generate research from and hit Enter. Maximum 2 allowed."
              id="source_urls"
              mode="tags"
              options={[]}
              onChange={formik.setFieldValue}
              value={formik.values.source_urls}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div>
                    <b>copy/paste url from browser and hit enter to add. </b>
                  </div>
                </div>
              )}
            />
          </div>
        )}
      </Space>
      <div className="mt-7 flex">
        <CustomButton title="Cancel" size={"small"} type="button" varient="error" onClick={clear}  />
        <CustomButton title="Create" type="submit" width="100px" />
      </div>
    </form>
  );
};

BlogForm.propTypes = {};

export default BlogForm;
