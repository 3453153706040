/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import api from "utils/api";
import { useAlert } from "context/alert";
import Input, { TextArea } from "components/Atoms/Input";
import { useAuth } from "context/auth";
import CustomButton from "components/Atoms/CustomButton";
import * as Yup from "yup";

const ProfileSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
});

const ProfileForm = () => {
  const { success, error } = useAlert();
  const {
    data: { user },
  } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      bio: "",
      name: "",
    },
    validationSchema: ProfileSchema,
    onSubmit: async (values) => {
      try {
        await api.put(`profile/${user.id}`, { name: values.name });
        success("User information is updated..!");
      } catch (err) {
        console.log(err);
        error("Error while updating the user data.");
      }
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({ email: user.email, name: user.name });
    }
  }, [user]);

  return (
    <div className="w-[848px]">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-12 mt-6">
          <div className="min-w-[400px]">
            <Input
              name="Name"
              type="name"
              id="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.errors.name}
              touched={formik.touched.name}
            />
          </div>
          <div className="min-w-[400px]">
            <Input
              name="Email"
              type="email"
              id="email"
              disabled={true}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
          </div>
        </div>
        <div>
          <TextArea name="Bio" id="bio" rows={4} />
        </div>
        <div className="mt-5">
          <CustomButton title="Update" type="submit" className="w-28" />
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
