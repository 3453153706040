import React, { createContext, useContext } from "react";
import message from "antd/lib/message";

const getConfig = (type, content, duration) => {
  return {
    type,
    content,
    duration,
    style: {
      position: "absolute",
      "font-size": 20,
      margin: 20,
      top: 0,
      right: 0,
      background: "rgb(37 99 235)",
      'border-radius': 10
    },
  };
};

function AlertProvider({ children }) {
  const [alert, contextHolder] = message.useMessage();

  const success = (msg, duration=7) => alert.open(getConfig("success", msg, duration));
  const error = (msg, duration=7) => alert.open(getConfig("error", msg, duration));

  return (
    <AlertContext.Provider value={{ success, error }}>
      {contextHolder}
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
export const AlertContext = createContext();

export const useAlert = () => {
  return useContext(AlertContext);
};

