import React from "react";
import Input, { TextArea } from "components/Atoms/Input";
import CustomButton from "components/Atoms/CustomButton";
import { useFormik } from "formik";
import api from "utils/api";
import { useAlert } from "context/alert";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/auth";
import Select from "components/Atoms/Select";
import { Divider, Button } from "antd";
import * as Yup from "yup";
import { setDefaultWorkspace } from "utils/helper";
import { DownloadOutlined } from '@ant-design/icons';
import { fieldHelperTexts, demoWorkspace } from "utils/constants";
import { useCollapse } from 'react-collapsed';

const WorkspaceSchema = Yup.object().shape({
  overview: Yup.string()
    .min(20, "Minimum 20")
    .max(fieldHelperTexts["SPACE_OVERVIEW_CHARLIMIT"], "Too Long!")
    .required("Required"),
});



const WorkspaceForm = () => {
  const { success, error } = useAlert();
  const { isAuthenticated, setData } = useAuth();
  const navigate = useNavigate();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const formik = useFormik({
    //validationSchema: WorkspaceSchema,
    initialValues: {
      name: "",
      features: [],
      blogCategories: [],
      overview: "",
    },
    onSubmit: async (values) => {
      try {
        const data = await api.post("space", {
          ...values,
          features: values.features?.join("|"),
          blogCategories: values.blogCategories?.join("|"),
        });
        setDefaultWorkspace(data.id);
        setData({ workspace: data });
        success("Workspace is created!");
        navigate("/dashboard/blogs");
        console.log(data);
      } catch (err) {
        console.log(err);
        error(err.message ? err.message[0] : "Error while creating workspace");
      }
    },
  });

  const fillDemo = () => {
    console.log("fillDemo");
    formik.setValues({
      name: demoWorkspace?.name,
      features: demoWorkspace?.features?.split("|"),
      blogCategories: demoWorkspace?.blogCategories?.split("|"),
      overview: demoWorkspace?.overview
    })
  };

  return (
    <div className="flex px-12 mt-3">
      <div>
      <Divider></Divider>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Input
              name="Name of your workspace"
              id="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder={fieldHelperTexts["WORKSPACE_NAME"]}
            />
          </div>
          <div>
            <Select
              name="Search Engine topics you want to generate authority for. ( just enter one topic for building topical authority articles )"
              id="blogCategories"
              mode="tags"
              onChange={formik.setFieldValue}
              value={formik.values.blogCategories}
              placeholder={fieldHelperTexts["WORKSPACE_BLOG_CATEGORIES"]}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div>
                    <b>type and select keywords to add</b>
                  </div>
                </div>
              )}
            />
          </div>
          <div className="collapsible">
              <div className="header" {...getToggleProps()} style={{color: "#2563EB"}}>
                  {isExpanded ? 'Hide Advanced' : 'Advanced Options'}
              </div>
              <div {...getCollapseProps()}>
                <div>
                  <small>Below options are used in every article that will be generated in workspace. You can provide overview for each article and for each article outline additionally in workspace. <br/><br/></small>
                </div>
                <div>
                  <TextArea
                    name="3-4 lines overview of your workspace (used in every article, keep empty if you want to provide this information per article.)"
                    rows={4}
                    id="overview"
                    type="textarea"
                    onChange={formik.handleChange}
                    value={formik.values.overview}
                    placeholder={fieldHelperTexts["WORKSPACE_OVERVIEW"]}
                  />
                  <p align="right">
                    <small>
                      <span style={{ color: "red", fontWeight: "bolder" }}>{formik.errors.overview}</span>{" "}
                      {formik.values.overview.length}/
                      {fieldHelperTexts["SPACE_OVERVIEW_CHARLIMIT"]} characters
                    </small>
                  </p>
                </div>
                <div>
                  <Select
                    name="And few features not covered in overview ( again, used in every article. Keep empty if you want to provide information based on article title later. )"
                    id="features"
                    mode="tags"
                    options={[]}
                    onChange={formik.setFieldValue}
                    value={formik.values.features}
                    placeholder={fieldHelperTexts["WORKSPACE_FEATURES"]}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div>
                          <b>start typing and select option to add</b>
                        </div>
                      </div>
                    )}
                  />
                </div>
                {!localStorage.getItem("selectedWorkspace") && (
                  <div className="items-center flex justify-center mt-2 mb-5" style={{background: "#222222", padding: "10px", marginBottom: "15px", borderRadius: "5px", color:"#ffffff" }}>
                      <div className="text-sm ml-2">New to Kwegg?  <Button type="dashed" onClick={fillDemo} icon={<DownloadOutlined style={{color:"#00FFFF"}} />}><b style={{color: "#00FFFF"}}>Click to Fill Demo Content</b></Button> to try Kwegg. With Kwegg, you can create multiple workspaces for free to represent your product or service organization's content website.</div>
                      <div><small style={{color:"#00FFFF", fontSize: "10px"}}>This content will create a workspace that will keep generating content talking about a product called "Genius7". If your goal is SEO around a particular topic and not talking just about your product, create a workspace with just keywords filled in not using Advanced options and provide information in article if feel necessary.  </small></div>
                  </div>
                )}
              </div>
          </div>
          
          
          <div className="mb-5 mt-6">
            <CustomButton type="submit" title="Start Generating Content" />
          </div>
          {localStorage.getItem("selectedWorkspace") && (
            <div className="flex text-blue-light">
              <div
                className="w-9 cursor-pointer content-center"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

WorkspaceForm.propTypes = {};

export default WorkspaceForm;
