import React, { useState } from "react";
import Avatar from "components/Atoms/Avatar";
import userImg from "images/parag.png";
import MagicLink from "components/Molecules/MagicLink";
import LoginForm from "components/Molecules/Forms/Login";
import RegisterForm from "components/Molecules/Forms/Register";

const Login = () => {
  const [show, setShow] = useState(false);
  const [isShowLogin, showLogin] = useState(false);
  const [email, setEmail] = useState("");
  return (
    <div className="login-background w-full h-screen">
      <div className="max-w-5xl mx-auto px-8">
        <div className="flex items-center justify-center h-screen ">
          <div className="bg-blue-light w-full md:w-2/5 hidden md:block relative bg-gray-600 rounded h-[600px]">
            <div className="flex flex-col pl-20 py-14 pr-11 text-white font-poppin justify-between h-[inherit]">
              <div className="">
                <div className="text-4xl font-bold">
                  Magic starts here 🧙
                </div>
                <div className="font-normal text-sm pt-4">
                  Lets fuel up your content marketing journey. 
                </div>
              </div>
              <div className="">
                <div className="font-normal text-sm">
                  We recommend providing clear and helpful information when questions are asked to help you get the most out of Kwegg and create amazing blogs.
                </div>
                <div className="flex items-center mt-5">
                  <div className="mr-2">
                    <Avatar src={userImg} />
                  </div>
                  <div className="flex flex-col text-xs">
                    <div className="font-semibold">Parag Arora</div>
                    <div className="font-normal">Founder, Kwegg.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/5 font-poppin relative md:px-0 py-16 bg-white h-[600px]">
            {show ? (
              <MagicLink setShow={setShow} email={email} />
            ) : isShowLogin ? (
              <LoginForm
                setShow={setShow}
                setEmail={setEmail}
                showLogin={showLogin}
              />
            ) : (
              <RegisterForm showLogin={showLogin} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {};

export default Login;
