import Button from "antd/lib/button";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdMenuOpen, MdHorizontalRule } from "react-icons/md";

const Item = ({ item, index, updateItem, deleteItem }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(item.value.split('~~')[0]);
  const [valueOverview, setValueOverview] = useState(item.value.split('~~')[1]);
  const handleEdit = (e) => {
    setValue(e.target.value);
    updateItem(index, e.target.value + '~~' + valueOverview);
  };
  const handleEditOverview = (e) => {
    setValueOverview(e.target.value);
    updateItem(index, value + '~~' + e.target.value);
  };
  return (
    <Draggable draggableId={item.id.toString()} index={index}>
      {(provided) => (
        <div
          className="mb-3"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {editing ? (
            <div className="p-1 bg-gray-100 flex items-center">
              <MdMenuOpen className="rotate-180" />
              <input
                className="my-1 mx-2 p-1 bg-white custom-width-full flex-1"
                type="text"
                value={value}
                onChange={handleEdit}
                onBlur={() => setEditing(false)}
              />
              <MdHorizontalRule onClick={() => deleteItem(index)} />
            </div>
          ) : (
            <div className="p-1 bg-gray-100 flex items-center">
              <MdMenuOpen className="rotate-180" />
              <div
                className="my-1 mx-2 p-1 bg-white rounded-md border flex-1 min-h-[31px]"
                onClick={() => setEditing(true)}
              >
                {value}
              </div>
              <MdHorizontalRule onClick={() => deleteItem(index)} />
            </div>
          )}
          {provided.placeholder}

          <textarea
                id="list"
                rows="1"
                placeholder="additional context or overview rough idea for this outline"
                value={valueOverview}
                onChange={handleEditOverview}
                className="rounded-md border text-grey-600 border-grey-600 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
              />
        </div>
      )}
    </Draggable>
  );
};

const Outline = ({ data, setData }) => {
  const [items, setItems] = useState(
    data?.split("|").map((x, i) => ({ id: i, value: x }))
  );
  const updateItem = (index, value) => {
    const updatedList = items.map((i, idx) => (idx === index ? { id: index, value} : i));
    setItems(updatedList);
    setData("outline", updatedList.map((x) => x.value).join("|"));
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const itemsCopy = Array.from(items);
    const [removed] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, removed);
    setItems(itemsCopy);
    setData("outline", itemsCopy.map((x) => x.value).join("|"));
  };

  const deleteItem = (id) => {
    const itemsCopy = Array.from(items);
    itemsCopy.splice(id, 1);
    setItems(itemsCopy);
    setData("outline", itemsCopy.map((x) => x.value).join("|"));
  };

  const addNewItem = () => {
    const sampleObj = {id: items.length, value: ""}
    setItems([...items, sampleObj])
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <Item
                  key={item.id}
                  item={item}
                  index={index}
                  updateItem={updateItem}
                  deleteItem={deleteItem}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button onClick={addNewItem} >+ Add Outline</Button>
    </div>
  );
};

export default Outline;
