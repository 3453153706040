import useSwr from "swr";


export const useQuery = (path, refreshInterval = 0) => {
  if (!path) {
    throw new Error("Path is required");
  }
  const { data, error, mutate, isLoading } = useSwr(path, { refreshInterval });

  return { data, error, mutate, isLoading };
};
