/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormik } from "formik";
import api from "utils/api";
import { useAlert } from "context/alert";
import Input from "components/Atoms/Input";
import { useAuth } from "context/auth";
import CustomButton from "components/Atoms/CustomButton";
import * as Yup from "yup";

const ProfileSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Required"),
  newPassword: Yup.string().required("Required"),
  reNewPassword: Yup.string().required("Required"),
});

const PasswordForm = () => {
  const { success, error } = useAlert();
  const {
    data: { user },
  } = useAuth();
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
    },
    validationSchema: ProfileSchema,
    onSubmit: async (values) => {
      if (values.newPassword !== values.reNewPassword) {
        error('New password and re enter new password should match')
        return
      }
      try {
        await api.post(`auth/change_password`, { password: values.currentPassword, new_password: values.newPassword, email: user.email });
        success("User information is updated..!");
      } catch (err) {
        console.log(err);
        error("Error while updating the user data.");
      }
    },
  });

  return (
    <div className="w-[848px]">
      <form onSubmit={formik.handleSubmit}>
          <div>
          <Input
            name="Current Password"
            type="password"
            id="currentPassword"
            onChange={formik.handleChange}
            value={formik.values.currentPassword}
            error={formik.errors.currentPassword}
            touched={formik.touched.currentPassword}
          />
          </div>
        <div>
        <Input
            name="New Password"
            type="password"
            id="newPassword"
            onChange={formik.handleChange}
            value={formik.values.newPassword}
            error={formik.errors.newPassword}
            touched={formik.touched.newPassword}
          />
        </div>
        <div>
        <Input
            name="Re-Type Password"
            type="password"
            id="reNewPassword"
            onChange={formik.handleChange}
            value={formik.values.reNewPassword}
            error={formik.errors.reNewPassword}
            touched={formik.touched.reNewPassword}
          />
        </div>
        <div className="mt-5">
          <CustomButton title="Update" type="submit" className="w-28" />
        </div>
      </form>
    </div>
  );
};

export default PasswordForm;
