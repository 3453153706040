import Input, { TextArea } from "components/Atoms/Input";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import useStrategy from "hooks/useStrategy";
import useTone from "hooks/useTone";
import Select from "components/Atoms/Select";
import { Divider, List, Typography, Col, Row, Space } from "antd";
import { EyeFilled } from '@ant-design/icons';
import api from "utils/api";
import CustomButton from "components/Atoms/CustomButton";
import Editor from "components/Molecules/Editor";
import Outline from "../Outline";
import { copyTextToClipboard, timeAgo } from "utils/helper";
import { useAlert } from "context/alert";
import { stages } from "utils/constants";
import { fieldHelperTexts, fieldLabels } from "utils/constants";
import { sentencesImprovements, recommendedSentenceLengthPerc } from "utils/contentAnalysis";
import { dangerousHtmlMarkup } from "utils/helper";

const BlogUpdate = ({ data, setDrawer, refetch, onBlogUpdate }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isSocialCopied, setIsSocialCopied] = useState(false);
  const { data: strategies } = useStrategy();
  const { data: tones } = useTone();
  const { success, error } = useAlert();

  const formik = useFormik({
    initialValues: {
      title: data?.title,
      overview: data?.overview,
      introduction: data?.introduction,
      tone: data?.tone?.split("|"),
      strategy: data?.strategy,
      body: data?.body || "",
      socialPost: data?.socialPost || "",
      outline: data?.outline,
      metas: (data?.metas ? JSON.parse(data?.metas):data?.metas),
      updatedAt: data?.updatedAt,
      createdAt: data?.createdAt
    },
    onSubmit: async (values) => {
      console.log(values);
      console.log(data);
      try {
        await api.put(`article/${data.id}`, {
          ...values,
          tone: values?.tone?.join("|"),
          strategy: values?.strategy,
        });
        success("Article updating successfully");
        onBlogUpdate();
        //setDrawer(false);
        refetch();
      } catch (err) {
        error("Error while updating article");
      }
    },
  });

  useEffect(() => {
    const updateInterval = setInterval(() => {
      // Call the submit function here
      // You can access the formikProps and call submitForm()
      if(data?.stages == "PUBLISHED") {
        console.log("Skipping published update");
        return;
      }
      formik.submitForm();
    }, 20000);

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(updateInterval);
    };
  }, []);

  const deleteArticle = async () => {
    try {
      await api.put(`article/${data.id}`, { stages: stages.ARCHIVED });
      success("Article deleted successfully");
      setDrawer(false);
      refetch();
    } catch (err) {
      error("Error while deleting article");
    }
  };

  const removeHighlights = () => {
    let newVal = formik.values.body.replace(/<span\s+[^>]*>(.*?)<\/span>/gi, '$1'); //remove all styles, can do very specific too later
    newVal = newVal.replace(/ style="background-color: (.*?);"/gi, '');
    formik.setFieldValue("body", newVal);
  }

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    const copy = copyTextToClipboard(formik.values.body);
    try{
      // If successful, update the isCopied state value
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch(err) {
        error("Error" + err);
        console.log(err);
      };
  };

  const handleSocialCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    const copy = copyTextToClipboard(formik.values.socialPost);
    try{
      // If successful, update the isCopied state value
      setIsSocialCopied(true);
      setTimeout(() => {
        setIsSocialCopied(false);
      }, 1500);
    } catch(err) {
        error("Error" + err);
        console.log(err);
      };
  };

  const selectSentences = (sentences) => {
    let newValue = formik.values.body;
    for(let j=0; j<sentences.length; j++) {
      let phrase = sentences[j];
      const highlightPhrase = '<span style="background-color: rgb(244, 168, 150);">'+phrase+'</span>'; // same color is used in removeHighlights. Take care there too when changing. 
      newValue = newValue.replace(phrase, highlightPhrase);
    }
    formik.setFieldValue("body", newValue);
  };

  const metasForm = (metasString) => {
    const metas = JSON.parse(metasString);
    console.log(metas);
    let genForm = ""
    for (const [key, value] of Object.entries(metas)) {
      // Create a label element with the key as the text content
      const label = document.createElement("label");
      label.textContent = key;
      
      // Create an input element with the value as the default value
      const input = document.createElement("input");
      input.type = "text";
      input.name = key;
      input.value = value;
      
      genForm += '<div><div className="text-xl">'+key+'</div><div className="text-sm text-grey-600"></div></div>' + 
      '<TextArea name="'+key+'" id="'+key+'" value="'+value+'"/>';
      console.log(genForm);
    }
    return genForm;
  }

  const contentAnalysisWidget = (htmlText) => {
    let responses = sentencesImprovements(htmlText);
    console.log(responses);
    let copyscapeUrl = "https://www.copyscape.com/?q=https%3A%2F%2Fapi.kwegg.com%2Fpublic%2Farticlepreview%2F"+data.id;
    let response = [];
    response.push({text: "Scan for plagiarism over copyscape and identify instances of content theft", issue: true, actions: [<a href={copyscapeUrl} target="_blank" class="button">Plagiarism Scan</a>]});
    response.push({text: "Verify OpenAI classifier tag generated text as unlikely generated by AI", issue: true, actions: [<a href="https://platform.openai.com/ai-text-classifier" target="_blank" class="button">Verify Now</a>, <a href="https://developers.google.com/search/blog/2023/02/google-search-and-ai-content#:~:text=and%20Google%20Search%3A-,Is%20AI%20content%20against%20Google%20Search's%20guidelines%3F,is%20against%20our%20spam%20policies." target="_blank">Google Stand on AI Generated Content</a>]});
    
    if(responses.passiveVoice.score < 7) {
      response.push({text: responses.passiveVoice.percent + "% of sentences contain passive voice which is more than recommended maximum of 10%. Try to use their active counterparts.", issue: true, actions: [<a onClick={() => selectSentences(responses.passiveVoice.sentences)}><EyeFilled /></a>]});
    } else {
      response.push({text: "Passive Voice Check: You're using enough active voice. Thats great!"});
    }

    if(responses.sentenceTransitions.score < 7) {
      response.push({text: responses.sentenceTransitions.percent + "% of sentence contains transition words. Use more of them. Recommended more than 30.", issue: true});
    } else {
      response.push({text: responses.sentenceTransitions.percent + "% of sentence contains transition words. Good work."});
    }

    if(responses.repeatedSentences.score < 7) {
      response.push({text: responses.repeatedSentences.count + " consecutive sentences starting with the same word. Click to highlight and mix things up", issue: true, actions: [<a onClick={() => selectSentences(responses.repeatedSentences.sentences)}><EyeFilled /></a>]});
    } else {
      response.push({text: "Consecutive Sentences Check: You're using enough variety in sentences. This is a good thing."});
    }

    if(responses.aiHighFreqSentences.count > 0) {
      let sentencePhrase = (responses.aiHighFreqSentences.count > 1) ? responses.aiHighFreqSentences.count + " sentences" : responses.aiHighFreqSentences.count + " sentence";
      response.push({text: sentencePhrase + " contains phrases most likely seen in AI sentences. Check them and correct if needed.", issue: true, actions: [<a onClick={() => selectSentences(responses.aiHighFreqSentences.sentences)}><EyeFilled /></a>]});
    }

    if(responses.lengthySubheadingTexts.score < 7) {
      response.push({text: responses.lengthySubheadingTexts.count + " sections of your text are longer than recommended 300 words not separated by subheadings. Add more subheadings", issue: true, actions: [<a onClick={() => selectSentences(responses.lengthySubheadingTexts.sentences)}><EyeFilled /></a>]});
    } else {
      response.push({text: "Subheading distribution looks good. "});
    }

    if(responses.sentenceLength.score < 7) {
      response.push({text: responses.sentenceLength.percent + "% of the sentences contain more than 20 words which is more than recommended maximum of 25%. Try shrinking them.", issue: true, actions: [<a onClick={() => selectSentences(responses.sentenceLength.sentences)}><EyeFilled /></a>]});
    } else {
      response.push({text: responses.sentenceLength.percent + "% of the sentences contain more than 20 words which is below recommended maximum of 25%. Good going."});
    }

    if(responses.lengthyParagraphs.score < 7) {
      response.push({text: responses.lengthyParagraphs.count + " of the paragraphs contains more than recommended maximum of 150. Shorten them.", issue: true, actions: [<a onClick={() => selectSentences(responses.lengthyParagraphs.sentences)}><EyeFilled /></a>]});
    } else {
      response.push({text: "Maximum Paragraph Length Check: None of the paragraph are too long. Nice job."});
    }

    if(responses.textLength.score < 7) {
      response.push({text: responses.textLength.count + " content words. We recommend to keep above 500. Try AI Expand sentences where paragraphs are short. ", issue: true});
    } else {
      response.push({text: "Content Length check passed."});
    }

    if(responses.links.count < 2) {
      response.push({text: responses.links.count + " link. Ensure you have quality outside referencable links to establish usefulness to readers for great SEO results.", issue: true});
    }
    
    
    return response;
  };

  return (
    <div className="font-poppin">
      <form onSubmit={formik.handleSubmit}>
        <div>
          <small>Updated {timeAgo(formik.values.updatedAt)} | </small>
          <small>Created {timeAgo(formik.values.createdAt)}</small>
        </div>
        <div>
          <Input
            name="Title"
            id="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            // error={formik.errors.title}
            // touched={formik.touched.title}
          />
        </div>
        <Divider style={{ margin: "10px 0" }} />
        {formik.values.body && (
          <>
            {" "}
            <div className="mt-4">
              <div>
                <div className="text-xl">Blog Generated</div>
                <div className="text-sm text-grey-600">
                  You can edit or modify your blog here.
                </div>
              </div>
              <div className="min-h-[300px] w-100 root-medium-editor-div" id="scroll-container">
                <Editor
                  id="body"
                  data={data}
                  value={formik.values.body}
                  onChange={formik.setFieldValue}
                />
                <p align="right"><small>{formik.values.body.split(" ").length} words/{formik.values.body.length} characters</small></p>
                <p align="right">
                  <a href='#' onClick={removeHighlights} className="text-sm">
                    <span className="text-blue">Remove Highlights </span>
                  </a> | 
                  <a href='#' onClick={handleCopyClick} className="text-sm">
                    <span className="text-blue">{isCopied ? ' Copied!' : ' Copy'}</span>
                  </a>
                </p>
              </div>
            </div>
            <div className="text-xl text-blue">
              <List
                size="small"
                header={<b>SEO and Readability Analysis</b>}
                footer={<div></div>}
                bordered
                dataSource={ contentAnalysisWidget(formik.values.body) }
                renderItem={(item) => 
                  item.issue?
                <List.Item actions={item.actions}><Typography.Text mark>{item.text}</Typography.Text></List.Item>:
                <List.Item>{item.text}</List.Item>
              }
              />
            </div>
            <Divider style={{ margin: "10px 0" }} />
          </>
        )}
        {formik.values.metas && (
          <div>
            {Object.entries(formik.values.metas).map(([key, value]) => (
              <div key={key}>
                {key!="research" && (
                  <div>
                    <div>
                      <div className="text-sm text-grey-600">{key}</div>
                    </div>
                    <TextArea
                      readOnly={true}
                      id={key}
                      type="textarea"
                      onChange={formik.handleChange}
                      value={value}
                    />
                    <p align="right">
                      <a href='#' onClick={()=>copyTextToClipboard(value)} className="text-sm">
                        <span className="text-blue">Copy</span>
                      </a>
                    </p>
                  </div>
              )}
              </div>
            ))}
          </div>
        )}
        {formik.values.socialPost && (
            <div>
              <div>
                <div className="text-xl">Social Media Post</div>
                <div className="text-sm text-grey-600">
                </div>
              </div>
              <TextArea
                name="Use this as social media post when sharing published article"
                id="socialPost"
                type="textarea"
                rows={3}
                onChange={formik.handleChange}
                value={formik.values.socialPost}
              />
              <p align="right">
                  <a href='#' onClick={handleSocialCopyClick} className="text-sm">
                    <span className="text-blue">{isSocialCopied ? 'Copied!' : 'Copy'}</span>
                  </a>
                </p>
            </div>
          )}
        <Divider>Training Inputs</Divider>
        <div className="mt-4">
          <div>
            <TextArea
              name="Overview for Content"
              id="overview"
              type="textarea"
              rows={4}
              onChange={formik.handleChange}
              value={formik.values.overview}
              placeholder={fieldHelperTexts["OVERVIEW_HELPER"]}
            />
            {formik.values.overview && (
              <p align="right"><small>{formik.values.overview.length} characters</small></p>
            )}
          </div>
          {(formik.values.metas && formik.values.metas && formik.values.metas.research) &&(
              <div>
                {formik.values.metas.research.summary && (<label>Generated Research to enrich overview available later to enrich draft as well</label>)}
                  <div style={{background: "#f59e0b1a", padding: "10px"}}>
                  {formik.values.metas.research.summary && (
                    <div style={{color: "#78350f", fontWeight: "bold"}}>
                      <div style={{fontSize: "16px"}}>Summary</div>
                      <div dangerouslySetInnerHTML={dangerousHtmlMarkup(formik.values.metas.research.summary.split('\n').join('<br/>'))} ></div>
                    </div>
                  )}
                  <Space/>
                  {formik.values.metas.research.summary && (<div style={{color: "#333333", marginTop: "20px"}}>Sources</div>)}
                  {formik.values.metas.research.webPages && formik.values.metas.research.webPages.map((item, index) => (
                      <div style={{background: "#222222", padding: "10px", marginBottom: "15px", borderRadius: "5px", color:"#ffffff" }}>
                        <div>
                          <Row>
                            <Col span={6}><img src={item.image} style={{padding: 5, maxHeight: "150px"}} /></Col>
                            <Col span={15}>
                              <a href={item.url} target="_blank">
                                <Row>
                                  <span style={{fontSize: 14}}>{item.name}</span>
                                </Row>
                                <Row>
                                <span style={{fontSize: 10, color: "#f59e0b"}}>{item.url}</span>
                                </Row>
                                <Row>
                                <span style={{fontSize: 12}}>{item.snippet}</span>
                                </Row>
                              </a>
                            </Col>
                          </Row>
                          <Row>
                          <span style={{fontSize: 11}}><b>Page Summary: </b>{item.summary}</span>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </div>
              </div>
            )}
          {formik.values.introduction && (
            <div>
              <TextArea
                name="Introduction"
                id="introduction"
                type="textarea"
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.introduction}
              />
              <p align="right"><small>{formik.values.introduction.length} characters</small></p>
            </div>
          )}
          <div>
            <Select
              name="Tone"
              mode="tags"
              id="tone"
              options={tones}
              onChange={formik.setFieldValue}
              value={formik.values.tone}
              error={formik.errors.tone}
              touched={formik.touched.tone}
              placeholder={fieldHelperTexts["TONE_HELPER"]}
            />
          </div>
          <div>
            <Select
              name="Article Strategy"
              id="strategy"
              options={strategies}
              onChange={formik.setFieldValue}
              value={formik.values.strategy}
              error={formik.errors.strategy}
              touched={formik.touched.strategy}
              placeholder={fieldHelperTexts["STRATEGY_HELPER"]}
            />
            <small>{(formik.values.strategy=='') ? "Select a strategy to learn more.":fieldHelperTexts[formik.values.strategy]}</small>
          </div>
        </div>
        <Divider style={{ margin: "10px 0" }} />
        {data?.outline && (
          <div className="mt-4">
            <div className="mb-3">
              <div className="text-xl">Blog Outline</div>
              <div className="text-sm text-grey-600">
                Drag or delete the subtitles as your preference
              </div>
            </div>
            <Outline data={data?.outline} setData={formik.setFieldValue} />
          </div>
        )}
        <div>
          <div className="mt-7 flex">
            <CustomButton
              title="Delete"
              type="button"
              varient="error"
              onClick={deleteArticle}
            />
            <CustomButton title="Update" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BlogUpdate;
