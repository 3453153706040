export const arrangeArticles = (data = []) => {
  const result = {
    TITLE_CREATED: [],
    CREATE_OUTLINE: [],
    CREATED_OUTLINE: [],
    CREATE_DRAFT: [],
    CREATED_DRAFT: [],
  };
  data.forEach((article) => {
    const list = result[article.stages];
    if (list) {
      result[article.stages] =  [...list, article ];
    }
  });
  return result
};
