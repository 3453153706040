import React, { useLayoutEffect, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
// import ImageResize  from 'quill-image-resize-module';
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import katex from "katex";
import "katex/dist/katex.min.css";
import CustomToolbar from "./Toolbar";
import { useState } from "react";
import { Button, Space } from "antd";
import { useAlert } from "context/alert";
import api from "utils/api";
import { Spin } from 'antd';
import { fieldHelperTexts } from "utils/constants";
window.katex = katex;


// Quill.register('modules/ImageResize',ImageResize);
const Editor = ({ id, data, value, onChange }) => {
  const [mousePos, setMousePos] = useState({});
  const [selected, setSelected] = useState({});
  const [article, setArticle] = useState(value);
  let toolbarRef = useRef();
  const [isLoaded, setLoaded] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const { success, error } = useAlert();
  const handleChange = (e) => {
    onChange(id, e);
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  const onFocusCb = (range, source, editor) => {
    // https://github.com/quilljs/quill/issues/1690 # fixes autoscroll to top for changing font
    document.querySelectorAll(".ql-picker").forEach(tool => {
      tool.addEventListener("mousedown", function(event) {
        event.preventDefault();
        event.stopPropagation();
      });
    });
  }

  const onEditorChangeSelectionCb = (range, source, editor) => {
    //console.log("::onEditorChangeSelectionCb")
    if(aiLoading || !range) return;
    try {
      console.log(editor);
      const n = editor.getHTML().match(/<img/gim).length;
      var text = editor.getText();
      text = text.substring(range.index - n,range.index + range.length - n);
      console.log(text, range);
      setSelected({text: text, mousePos: mousePos, range: range});
    } catch(e){
      console.log(e);
    }
  }

  const rewriteArticle = async (option, selected) => {
    console.log("rewrite clicked");
    console.log(selected);
    setAiLoading(true);
    rewriteQuery(option, selected);
    setTimeout(() => {
      setSelected({text: null, mousePos: mousePos, range: null});
      (window.getSelection ? window.getSelection() : document.selection).empty();
    }, 3000);
    
  }

  const rewriteQuery = async (option, selected) => {
    try {
      if(selected.text.length > fieldHelperTexts["TITLE_OVERVIEW_CHARLIMIT"]) {
        alert("Not supported for more than one paragraph or "+fieldHelperTexts["TITLE_OVERVIEW_CHARLIMIT"]+" characters.");
      } else {
        const phrase = await api.post(`article/${data.id}/rewrite`, {
          phrase: selected.text,
          option: option,
        });
        const highlightPhrase = '<span style="background-color: yellow;">'+phrase+'</span>';
        let newValue = value.replace(selected.text.trim(), highlightPhrase);
        if(value==newValue) {
          let selectedText = selected.text.replaceAll("&","&amp;");
          selectedText = selected.text.replaceAll("&","&amp;");
          newValue = value.replace(selectedText.trim(), highlightPhrase);
        }
        onChange(id, newValue);
        success("AI Rephrased");
        setTimeout(() => {
          const unhighlight = newValue.replace(highlightPhrase, phrase);
          onChange(id, unhighlight);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      error("Error while updating article");
    }
    setAiLoading(false);
  }

  const modules = {
    toolbar: {
      container: toolbarRef.current,
    },
  };
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "formula",
  ];

  useLayoutEffect(() => {
    if (toolbarRef?.current) {
      setLoaded(true);
    }
  }, [toolbarRef]);

  return (
    <>
      {selected.text && (
        <div className="tooltip-menu"
          style={{
            left: selected.mousePos.x,
            top: selected.mousePos.y - 50,
            position: 'fixed',
          }}
        >
          <Space align="center" className="tooltip-space" wrap>
            <Button type="primary" className="tool-primary-btn" onClick={()=>rewriteArticle("REPHRASE", selected)}>AI Rephrase</Button>
            <Button type="primary" className="tool-primary-btn" onClick={()=>rewriteArticle("EXPAND", selected)}>AI Expand</Button>
            <Button type="primary" className="tool-primary-btn" onClick={()=>rewriteArticle("SHRINK", selected)}>AI Shrink</Button>
            <Button type="primary" className="tool-primary-btn" onClick={()=>rewriteArticle("PASSIVE", selected)}>Passive Voice</Button>
            <Button type="primary" className="tool-primary-btn" onClick={()=>rewriteArticle("ACTIVE", selected)}>Active Voice</Button>
            <Button type="text" className="tooltip-text">{selected.text.length} characters</Button>
          </Space>
        </div>
      )}
      {aiLoading && (
        <div
        style={{
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          backgroundColor: '#333',
          padding: '50px',
          margin: '20px',
          align: 'center',
          verticalAlign: 'center',
          zIndex: 3100,
          opacity: 0.8
        }}
        >
          <Spin size="Large"/>
        </div>
      )}
      <div>
      <CustomToolbar ref={toolbarRef} />
      {isLoaded && (
        <ReactQuill
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          scrollingContainer={'#scrolling-container'}
          onFocus={onFocusCb}
          onChangeSelection={onEditorChangeSelectionCb}
        />
      )}
      </div>
    </>
  );
};

export default Editor;
