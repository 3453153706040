import Divider from "antd/lib/divider";
import CustomButton from "components/Atoms/CustomButton";
import Input, { TextArea } from "components/Atoms/Input";
import Select from "components/Atoms/Select";
import { useAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import api from "utils/api";
import { fieldHelperTexts } from "utils/constants";
import { Helmet } from 'react-helmet';

const WorkspaceTab = () => {
  const { success, error } = useAlert();
  const { data: appData, setData } = useAuth();
  const workspace = appData?.workspace;

  const formik = useFormik({
    //   validationSchema: WorkspaceSchema,
    initialValues: {
      name: "",
      features: [],
      blogCategories: [],
      overview: "",
    },
    onSubmit: async (values) => {
      try {
        const data = await api.put(`space/${workspace.id}`, {
          ...values,
          features: values.features?.join("|"),
          blogCategories: values.blogCategories?.join("|"),
        });
        success("Workspace is updated successfully!");
        setData({ workspace: data })
      } catch (err) {
        console.log(err);
        error(err.message ? err.message[0] : "Error while editing workspace");
      }
    },
  });

  useEffect(() => {
    if (workspace) {
      formik.setValues({
        name: workspace?.name,
        features: workspace?.features?.split("|"),
        blogCategories: workspace?.blogCategories?.split("|"),
        overview: workspace?.overview,
      });
    }
  }, [workspace]);

  return (
    <div className="px-12 mt-3 mx-10">
      <Helmet>
        <title>{appData?.workspace?.name+' | Workspace Edit Settings'}</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-7">
        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-lg">Name of your workspace</div>
            <div className="text-grey-600">
              This name will be displayed as your workspace name
            </div>
          </div>
          <div className="flex-1">
            <Input
              name=""
              id="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder={fieldHelperTexts["WORKSPACE_NAME"]}
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-lg">3-4 lines overview of your workspace</div>
            <div className="text-grey-600">
              Describe your workspace overview
            </div>
          </div>
          <div className="flex-1">
            <TextArea
              name=""
              rows={4}
              id="overview"
              type="textarea"
              onChange={formik.handleChange}
              value={formik.values.overview}
              placeholder={fieldHelperTexts["WORKSPACE_OVERVIEW"]}
            />
            <p align="right">
              <small>
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  {formik.errors.overview}
                </span>{" "}
                {formik.values.overview.length}/
                {fieldHelperTexts["SPACE_OVERVIEW_CHARLIMIT"]} characters
              </small>
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <div className="flex-1">
              <div className="text-lg">
                And few features not covered in overview
              </div>
            </div>
            <div className="flex-1">
              <Select
                name=""
                id="features"
                mode="tags"
                options={[]}
                onChange={formik.setFieldValue}
                value={formik.values.features}
                placeholder={fieldHelperTexts["WORKSPACE_FEATURES"]}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div>
                      <b>start typing and select option to add</b>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-lg">
              Search Engine keywords you want to generate content for.
            </div>
          </div>
          <div className="flex-1">
            <Select
              name=""
              id="blogCategories"
              mode="tags"
              onChange={formik.setFieldValue}
              value={formik.values.blogCategories}
              placeholder={fieldHelperTexts["WORKSPACE_BLOG_CATEGORIES"]}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div>
                    <b>type and select keywords to add</b>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="mb-5 mt-6 w-96 flex gap-4">
            <CustomButton type="submit" title="Update" />
            <CustomButton title="delete" varient="error" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WorkspaceTab;
