import React, { useEffect, useState } from "react";
import Table from "antd/lib/table";
import usePublished from "hooks/usePublished";
import { useAuth } from "context/auth";
import Drawer from "components/Molecules/Drawer";
import BlogUpdate from "components/Molecules/Forms/BlogUpdate";
import { Helmet } from 'react-helmet';

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const PublishList = () => {
  const [active, setActive] = useState(null);
  const columns = [
    {
      title: "id",
      dataIndex: "index",
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    {
      title: "Title",
      dataIndex: "title",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: "blogtype",
      dataIndex: "blogtype",
    },
    {
      title: "strategy",
      dataIndex: "strategy",
    },
    {
      title: "tone",
      dataIndex: "tone",
    },
    {
      title: "Action",
      dataIndex: "",
      render: (data) => (
        <div
          className="cursor-pointer text-blue-600 font-semibold font-poppin"
          onClick={() => {
            setActive(data);
            setOpen(true);
          }}
        >
          {" "}
          Open{" "}
        </div>
      ),
    },
  ];
  const { data: appData } = useAuth();
  const { data: publishList } = usePublished(appData.workspace.id);
  const [isOpen, setOpen] = useState(false);

  const [list, setList] = useState([]);

  useEffect(() => {
    if (publishList?.length) {
      const cloneList = publishList.map((x, i) => ({ index: i + 1, ...x }));
      setList(cloneList);
    }
  }, [publishList]);

  return (
    <div>
      <Helmet>
        <title>{appData?.workspace?.name+' | Published'}</title>
      </Helmet>
      <Drawer
        isOpen={isOpen}
        setDrawer={setOpen}
        title="Update article"
        subTitle="Hit Update button when you are done updating."
        // buttonTitle={getPublishText(activeData, appData)}
        // buttonTitleHandler={onPublish}
      >
        {isOpen && (
          <BlogUpdate
            setDrawer={setOpen}
            // refetch={refetchArticles}
            data={active}
          />
        )}
      </Drawer>
      <Table columns={columns} dataSource={list} onChange={onChange} />
    </div>
  );
};

export default PublishList;
