import { useQuery } from './query';

function usePublished (id) {
    const { data, error, isLoading } = useQuery(`article/${id}/published`)

    return {
      data,
      isLoading,
      isError: error
    }
  }

export default usePublished;
