import React, { useState } from "react";
import ProfileForm from "../Forms/Profile";
import Divider from "antd/lib/divider";
import PasswordForm from "../Forms/Password";
import { useAuth } from "context/auth";
import { Helmet } from 'react-helmet';

const data = {
  profile: { title: "Profile Settings", subTitle: "" },
  password: {
    title: "Password Settings",
    subTitle: "please enter your current password to change your password",
  },
};
const Profile = () => {
  const [active, setActive] = useState("profile");
  const { data: appData } = useAuth();
  return (
    <div>
      <Helmet>
        <title>{appData?.workspace?.name+' | Profile'}</title>
      </Helmet>
      <div className="flex">
        <div className="flex-1 flex flex-col mt-5">
          <div
            onClick={() => setActive("profile")}
            className={`cursor-pointer ${
              active === "profile" ? "text-blue-600" : ""
            }`}
          >
            Profile
          </div>
          {/* <Divider type="horizontal" />
        <div className="cursor-pointer">Email</div> */}
          <Divider type="horizontal" />
          <div
            onClick={() => setActive("password")}
            className={`cursor-pointer ${
              active !== "profile" ? "text-blue-600" : ""
            }`}
          >
            Password
          </div>
        </div>
        <Divider style={{ height: "auto" }} type="vertical" />
        <div style={{ flex: 4 }}>
          <div className="font-poppin mb-3">
            <div className="text-2xl"> {data[active].title} </div>
            <div className="text-lg text-grey-600">
              {" "}
              {data[active].subTitle}{" "}
            </div>
          </div>
          <Divider type="horizontal" />

          {active === "profile" ? <ProfileForm /> : <PasswordForm />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
