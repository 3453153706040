import { useQuery } from './query';

function useSpace () {
    const { data, error, isLoading, mutate } = useQuery('space')

    return {
      data,
      isLoading,
      isError: error,
      mutate
    }
  }

export default useSpace;
