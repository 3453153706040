/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import Divider from "antd/lib/divider";
import { useAuth } from "context/auth";
import { Link, useNavigate } from "react-router-dom";
import { setDefaultWorkspace } from "utils/helper";
import Popover from "antd/lib/popover";

const WorkspaceSwitch = ({ spaces, list }) => {
  const { data, setData } = useAuth();
  const navigate = useNavigate();

  const changeSpace = (id) => {
    setDefaultWorkspace(id);
    setData({ workspace: spaces.find((x) => x.id === id) });
  };

  const content = (id) => (
    <div>
      <div> <Link to={`/workspace/${id}`} > Edit </Link></div>
      <div>Delete</div>
    </div>
  );

  return (
    <div className="w-60 overflow-hidden flex flex-col justify-between">
      <div>
        <div className="flex flex-col">
          <div>Current Workspace</div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={"https://ui-avatars.com/api/?name="+data?.workspace?.name}
                alt=""
                height={30}
                width={30}
                className="m-2"
              />
              <div>
                <div className="text-base"> {data?.workspace?.name} </div>
              </div>
            </div>
            <div>
              {" "}
              <Popover content={content(data?.workspace?.id)} trigger="click" placement="bottom" >
                <IoEllipsisVerticalSharp className="cursor-pointer" />
              </Popover>
            </div>
          </div>
          <Divider style={{ margin: "10px 0" }} />
        </div>
        <div className="flex flex-col">
          <div>Other Workspace</div>
          {list.map((item) => (
            <div key={item.id} className="flex items-center justify-between">
              <div
                className="flex items-center cursor-pointer"
                onClick={() => changeSpace(item.id)}
              >
                <img
                  src={"https://ui-avatars.com/api/?name="+item?.name}
                  alt=""
                  height={30}
                  width={30}
                  className="m-2"
                />
                <div>
                  <div className="text-base"> {item?.name} </div>
                  {/*<div className="text-xs">28 members</div>*/}
                </div>
              </div>
              <div>
                <Popover content={content(item.id)} trigger="click" placement="bottom" >
                  <IoEllipsisVerticalSharp className="cursor-pointer"  />
                </Popover>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Divider style={{ margin: "10px 0" }} />
        <div
          className="text-center cursor-pointer"
          onClick={() => navigate("/workspace-create")}
        >
          + Add another workspace
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSwitch;
