import React, { useEffect, useState } from "react";
import Card from "antd/lib/card";
import Switch from "antd/lib/switch";
import Modal from "antd/lib/modal";
import api from "utils/api";
import { useAuth } from "context/auth";
import { useAlert } from "context/alert";

const IntegrationCard = ({ title, info, Icon, Body, data, refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEnable, setEnable] = useState(false);
  const { data: appData } = useAuth();
  const { success, error } = useAlert();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeSwitch = async(checked) => {
    if (data?.id) {
      try {
        await api.post(`integration/${appData?.workspace?.id}`, {
          isEnabled: checked,
          id: data?.id,
          type: data?.type
        });
        success("Updated..!");
        setEnable(checked);
        refetch();
      } catch (err) {
        console.log(err);
        error("Error while updating");
      }
    }
  }

  useEffect(() => {
    if (data?.type) {
      setEnable(data.isEnabled)
    }
  }, [data])

  return (
    <Card
      style={{ width: 300, marginTop: 16 }}
      actions={[
        <div className="text-end mr-3" onClick={showModal}>
          View Integration
        </div>,
      ]}
    >
      <Modal
        title={
          <div className="flex items-center gap-1">
            <div>
              <Icon style={{ fontSize: "20px" }} />
            </div>
            <div> {title} Integration </div>
          </div>
        }
        footer={[]}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Body onClose={handleCancel} data={data} refetch={refetch} />
      </Modal>
      <div>
        <div className="flex justify-between mb-4">
          <div className="flex items-center gap-1">
            <div>
              <Icon style={{ fontSize: "20px" }} />
            </div>
            <div> {title} </div>
          </div>
          <div>
            {" "}
            <Switch disabled={!data?.id} checked={isEnable} onChange={onChangeSwitch} />{" "}
          </div>
        </div>
        <div>{info}</div>
      </div>
    </Card>
  );
};

export default IntegrationCard;
