import React from 'react'

const Dashboard = props => {
  return (
    <div>
        <div>Dashboard</div>
    </div>
  )
}

Dashboard.propTypes = {}

export default Dashboard