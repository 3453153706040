import Divider from "antd/lib/divider";
import Input from "components/Atoms/Input";
import { useAuth } from "context/auth";
import { useQuery } from "hooks/query";
import React from "react";
import IntegrationCard from "./Card";
import {ImWordpress} from 'react-icons/im';
import {SiWebflow} from 'react-icons/si';
import WordpressForm from "../Forms/Wordpress";
import WebflowForm from "../Forms/Webflow";
import { Helmet } from 'react-helmet';

const Integrations = () => {
  const { data: appData } = useAuth();
  const { data, mutate } = useQuery(
    `integration/${appData.workspace.id}`
  );

  return (
    <div>
      <Helmet>
        <title>{appData?.workspace?.name+' | Edit Integrations'}</title>
      </Helmet>
      <div>
        <div className="flex justify-between">
          <div>
            <div className="text-lg"> Custom Integration Settings </div>
            <div className="text-sm text-grey-600">
              Here, you can manage which notifications you want us to send you
            </div>
          </div>
          <div>
            <Input placeholder="Search" id="search" />
          </div>
        </div>
        <Divider style={{ margin: "10px 0" }} />
        <div className="flex gap-8">
          <IntegrationCard
            refetch={mutate}
            Icon={ImWordpress}
            Body={WordpressForm}
            data={data?.find(x => x.type === "WORDPRESS")}
            title="Wordpress"
            info="This is the wordpress integration for your website"
          />
          
        </div>
      </div>
    </div>
  );
};

export default Integrations;
/*
WEBFLOW INTEGRATION CARD FOR FUTURE
<IntegrationCard
            refetch={mutate}
            Icon={SiWebflow}
            Body={WebflowForm}
            title="Webflow"
            data={data?.find(x => x.type === "WEBFLOW")}
            info="This is the Webflow integration for your website"
          />
*/