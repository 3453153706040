import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import axios from "axios";
import { baseUrl } from "../utils/constants";
import { useState } from "react";

const Preview = (props) => {
  const { articleId } = useParams();
  const [article, setArticle] = useState({title: "Loading", body: "Loading.."});
  const [loading, setLoading] = useState(false);
  if(!loading) {
    setLoading(true);
    axios.get('http://localhost:3000/public/article/4d24f716-62fd-4585-ac70-aee67673c71c')
    .then(res => {
        const article = res.data;
        console.log(article);
        console.log(article.title);
        setArticle(article);
    });
  }

  return (
    <div>
        <Helmet>
            <title>{article.title} Preview</title>
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: article.body }} />
    </div>
  );
};

Preview.propTypes = {};

export default Preview;
