import React from "react";
import Input from "components/Atoms/Input";
import CheckBox from "components/Atoms/Checkbox";
import CustomButton from "components/Atoms/CustomButton";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import api from "utils/api";
import * as Yup from "yup";
import { useAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useNavigate } from "react-router-dom";
import { getDefaultWorkspace } from "utils/helper";
import { Spin } from "antd";
import { useState } from "react";
import { Helmet } from 'react-helmet';

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const LoginForm = ({ setShow, setEmail, showLogin }) => {
const { success, error } = useAlert();
const { setLogin, logout, setData } = useAuth();
const [processing, setProcessing] = useState(false);
const navigate = useNavigate();
  const formik = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setProcessing(true);
      try {
        const data = await api.post("auth/login", values);
        //TODO:  do same changes in VerifyUser.jsx - or best to move this to helper functions
        if (data?.isVerified) {
          success("Log in successfully");
          setLogin();
          setData({ user: data });
          localStorage.setItem("user", JSON.stringify(data));
          const spaces = await api.get("space");
          if (spaces?.length) {
            setData({ workspace: getDefaultWorkspace(spaces)})
            navigate('/dashboard/blogs');
          } else {
            navigate('/workspace-create');
          }
        } else {
          error(
            "Please verify your accout by clicking the link in your mail..!"
          );
          logout();
        }
      } catch (err) {
        console.log(err);
        error(err?.message);
      }
      setProcessing(false);
    },
  });
  return (
    <div className="pl-12 pr-12 md:pr-28">
      <Helmet>
        <title>{'Login'}</title>
      </Helmet>
      <div className="text-4xl font-bold">Login</div>
      <div className="font-normal text-sm text-grey-600 pt-3">
        Content Authority with Kwegg
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-6">
          <div>
            <Input
              name="Email address"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
          </div>
          <div className="font-normal text-xs font-grey-600 mt-1 mb-3 h-5">
            <div className="text-grey-light float-left">Long password. Hard to type? </div>
          <div
            onClick={() => {
              setEmail();
              if (formik.errors.email) {
                error("Error in email field. " + formik.errors.email);
                return;
              } else if (formik.values.email) {
                setEmail(formik.values.email);
                setShow(true);
              } else {
                alert("Please enter email to continue!");
              }
            }}
            className="text-blue-500 cursor-pointer float-right"
          >
            <a href='#'><b>Login via MagicLink</b></a>
          </div>
        </div>
          <div>
            <Input
              name="Password"
              type="password"
              id="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>
        </div>
        <div className="mt-7">
          <CustomButton varient={processing ? "disabled":"success"} title="Log in" type="submit" />
        </div>
      </form>
      <div className="flex text-xs mt-3">
        <div className="mr-1">Don't have an account?</div>
        <div className="text-blue-500 cursor-pointer" onClick={() => showLogin(false)} >Create free account</div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  setShow: PropTypes.func.isRequired,
};

export default LoginForm;
