import CustomButton from "components/Atoms/CustomButton";
import Input, { TextArea } from "components/Atoms/Input";
import Select from "components/Atoms/Select";
import { useAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useFormik } from "formik";
import React, { useState } from "react";
import api from "utils/api";
import { Helmet } from 'react-helmet';
import { Spin, List, Typography, Divider } from 'antd';
import ReactAudioPlayer from 'react-audio-player';


const CarouselGen = () => {
  const { success, error } = useAlert();
  const { data: appData } = useAuth();
  const [processing, setProcessing] = useState(false);
  const [videoResponse, setVideoResponse] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const workspace = appData?.workspace;

  const formik = useFormik({
    //   validationSchema: WorkspaceSchema,
    initialValues: {
      carouselurl: "",
      audioscript: null,
      lang: 'en'
    },
    onSubmit: async (values) => {
      setProcessing(true);
      console.log(workspace);
      try {
        const data = await api.post(`space/${workspace.id}/generate-video`, {
          url: values.carouselurl
        });
        setProcessing(false);
        success("Data Generated!");
        console.log(data);
        setVideoResponse(data);
        formik.setFieldValue('audioscript', data.audio_script);
      } catch (err) {
        setProcessing(false);
        console.log(err);
        alert(err.message ? err.message[0] : "Error while generating.");
        error(err.message ? err.message[0] : "Error while generating");
      }
    },
  });

  const generateAudio = async () => {
    setProcessing(true);
    console.log(workspace);
    try {
      const data = await api.post(`space/${workspace.id}/generate_audio`, {
        audiotext: formik.values.audioscript,
        lang: formik.values.lang
      });
      setProcessing(false);
      success("Audio Generated!");
      console.log(data);
      setAudioFile(data.Location);
    } catch (err) {
      console.log(err);
      error(err.message ? err.message[0] : "Error while editing workspace");
    }
  }


  return (
    <div className="px-12 mt-3 mx-10">
      <Helmet>
        <title>Generate Carousel</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-7">
        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-lg">Enter URL</div>
            <div className="text-grey-600">
              Enter article url here
            </div>
          </div>
          <div className="flex-1">
            <Input
              name=""
              id="carouselurl"
              onChange={formik.handleChange}
              value={formik.values.carouselurl}
              placeholder="URL here"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="mb-5 mt-6 w-96 flex gap-4">
            <CustomButton type="submit" title="Generate" />
          </div>
        </div>
      </form>
      <div>{audioFile&&(
        <div>
        <ReactAudioPlayer
          src={audioFile}
          autoPlay
          controls
        /><p><a href={audioFile}>Download</a></p></div>)}
      </div>
      <div>
        <p align="right">{processing && (<Spin />)}{processing ? ("Processing. Please wait here to not lose output.") : ("Costs 1 unit per minute.")}</p>
      </div>
      <div>{videoResponse && (
        <div>
          <div>
            <TextArea
              id="audioscript"
              type="textarea"
              onChange={formik.handleChange}
              value={formik.values.audioscript}
            />
          </div>
          <div align="right">
            <select
              name="lang"
              id="lang"
              value={formik.values.lang}
              onChange={formik.handleChange}
              style={{ display: "block" }}
            >
              <option value="en" label="English">
                English
              </option>
              <option value="hi" label="Hindi">
                Hindi
              </option>
            </select>
          </div>
          <div>&nbsp;<br /></div>
          <div align="right">
            <a href="#" onClick={generateAudio} class="px-6 py-3 text-blue-100 no-underline bg-blue-500 rounded hover:bg-blue-600 hover:underline hover:text-blue-200"><b>Generate Audio</b></a>
          </div>
          <Divider />
          <div>
            <List
              size="small"
              header={<b>Slides</b>}
              footer={<div></div>}
              bordered
              dataSource={videoResponse.clips}
              renderItem={(item) =>
                <List.Item>
                  <p><Typography.Text mark>{item.title}</Typography.Text></p>
                  <p>{item.subtitle}</p>
                  <p><img src={item.image_url} /></p>
                  <p>Image Search Keyword: {item.image_search_keyword}</p>
                </List.Item>
              }
            />
          </div>
        </div>)}
      </div>
    </div>
  );
};

export default CarouselGen;
