import api from "utils/api";
import { boardList } from "./constants";
import * as dayjs from 'dayjs';

export const checkIsAuthLogin = (data) => {
  const arr = data?.config?.url.split("/");
  if ((arr[arr.length - 1] === ("login"))||(arr[arr.length - 2] === ("verify"))) {
    return true;
  } else {
    return false;
  }
};

export const fetcher = (url) => {
  return api.get(url);
};

export const remove_ = (str) => str.replace(/_/g, " ");
export const add_ = (str) => str.replace(/ /g, "_");

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

export const stageChangeCondition = (s, d) => {
  const iS = boardList.indexOf(s);
  const iD = boardList.indexOf(d);
  // open for create_outline & create_draft
  if (iD === 1 || iD === 3){
    return true
  } else if (iD >= 1 && iS >= 0) {
    return iD - iS === 1;
  } else {
    return false;
  }
};

export const setDefaultWorkspace = (id) => {
  localStorage.setItem("selectedWorkspace", id);
};

export const getDefaultWorkspace = (spaces = []) => {
  const localId = localStorage.getItem("selectedWorkspace");
  if (localId) {
    const selectedSpace = spaces.find((x) => x.id === localId);
    if (selectedSpace) {
      return selectedSpace;
    } else {
      return spaces?.[0];
    }
  } else {
    return spaces?.[0];
  }
};

export const arrayMove = (list, from, to) => {
  let numberOfDeletedElm = 1;

  const elm = list.splice(from, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;

  list.splice(to, numberOfDeletedElm, elm);

  return list;
};

export const dangerousHtmlMarkup = (value) => {
  return { __html: value };
}

// error handler for swr

export const globleErrorHandler = (err) => {
  console.log(err)
  if (err.statusCode === 401) {
    localStorage.removeItem('token')
    window.location.href = window.location.origin + '/login'
  }
}

export const timeAgo = (timestamp) => {
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)
  const currentTime = dayjs();
  const timeAgo = dayjs(timestamp).from(currentTime);
  return timeAgo;
}


