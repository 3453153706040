import CustomButton from "components/Atoms/CustomButton";
import Input, { TextArea } from "components/Atoms/Input";
import Select from "components/Atoms/Select";
import { useAlert } from "context/alert";
import { useAuth } from "context/auth";
import { useFormik } from "formik";
import React, { useState } from "react";
import api from "utils/api";
import { fieldHelperTexts } from "utils/constants";
import { Helmet } from 'react-helmet';
import {Spin, Divider} from 'antd';
import ReactAudioPlayer from 'react-audio-player';


const AudioGen = () => {
  const { success, error } = useAlert();
  const { data: appData } = useAuth();
  const [processing, setProcessing] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const workspace = appData?.workspace;

  const formik = useFormik({
    //   validationSchema: WorkspaceSchema,
    initialValues: {
      audiotext: "",
      lang: "hi"
    },
    onSubmit: async (values) => {
      setProcessing(true);
      console.log(workspace);
      try {
        const data = await api.post(`space/${workspace.id}/generate_audio`, {
          ...values,
          audiotext: values.audiotext,
          lang: values.lang
        });
        setProcessing(false);
        success("Audio Generated!");
        console.log(data);
        setAudioFile(data.Location);
      } catch (err) {
        console.log(err);
        error(err.message ? err.message[0] : "Error while editing workspace");
      }
    },
  });

  
  return (
    <div className="px-12 mt-3 mx-10">
      <Helmet>
        <title>Generate Audio</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-7">
        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-lg">Audio Text here</div>
            <div className="text-grey-600">
              Enter your audio here
            </div>
          </div>
          <div className="flex-1">
            <TextArea
              name=""
              rows={4}
              id="audiotext"
              type="textarea"
              onChange={formik.handleChange}
              value={formik.values.audiotext}
              placeholder="Audio text here"
            />
            <p align="right">
              <small>
                <span style={{ color: "red", fontWeight: "bolder" }}>
                  {formik.errors.overview}
                </span>{" "}
                {formik.values.audiotext.length}/
                {fieldHelperTexts["SPACE_OVERVIEW_CHARLIMIT"]} characters
              </small>
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex-1">
            <div className="text-lg">Language</div>
            <div className="text-grey-600">
              Which Language? 
            </div>
          </div>
          <div className="flex-1">
            <select
                name="lang"
                id="lang"
                value={formik.values.lang}
                onChange={formik.handleChange}
                style={{ display: "block" }}
              >
                <option value="en" label="English">
                  English
                </option>
                <option value="hi" label="Hindi">
                  Hindi
                </option>
              </select>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="mb-5 mt-6 w-96 flex gap-4">
            <CustomButton type="submit" title="Generate" />
          </div>
        </div>
      </form>
      <div>{audioFile&&(
        <div>
        <ReactAudioPlayer
          src={audioFile}
          autoPlay
          controls
        /><p><a href={audioFile}>Download</a></p></div>)}
      </div>
      <p align="right">{processing&&(<Spin/>)}{processing?("Processing. Please wait here to not lose output."):("Costs 1 unit per minute.")}</p>
    </div>
  );
};

export default AudioGen;
